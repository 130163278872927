export function insert_stylesheet(fileName, comment = false) {
	var head = $(document.head);
	var link = document.createElement("link");

	link.type = "text/css";
	link.rel = "stylesheet";
	link.href = fileName;

	const commentOPEN = document.createComment(`SF INSERTED CSS ${comment || ""}:`),
		commentEND = document.createComment("END SF INSERTED CSS");
	head.append(commentOPEN);
	head.append(link);
	head.append(commentEND);

	return new Promise((resolve, reject) => {
		const linkInHead = head.find("link[href='" + fileName + "']");
		if (linkInHead.length) {
			linkInHead.on("load", () => resolve("stylesheet in <head> and loaded"));
		} else {
			reject("Could not find stylesheet link in <head>");
		}
	});
}
