import {FormValidator} from "../_classes";

export function initEditAccount() {
	// EDIT ACCOUNT VALIDATION:
	new FormValidator(
		"editaccount",
		{
			account_first_name: ["hasValue"],
			account_last_name: ["hasValue"],
			account_email: ["hasValue", "isEmail"],
			password_current: ["hasValue"],
			password_1: [
				"hasValue",
				"minLength8",
				"hasCapitalLetter",
				"hasLowercaseLetter",
				"hasNumber",
				"hasSpecialCharacter"
			],
			password_2: ["matchesDependant"]
		},
		{
			password_1: "password_current",
			password_2: "password_1"
		},
		false // prevent pseudo submit to allow the form to be submitted normally (prevent issues with the password fields)
	);

	// disable the password fields if the current password is empty:
	$("#password_current_field input").on("input", function () {
		$("#password_1_field, #password_2_field").addRmvClass(
			"disabled-input-container",
			$(this).val() === ""
		); // add or remove the disabled-input-container class from the password fields (if the current password is empty, disable the password fields, otherwise enable them)
		$("#password_1_field input, #password_2_field input").val(""); // clear the password fields if the current password is empty or changed
	});
}
