class QuantityChecker {
	constructor({formSelectors = "", inputSelectors = ""} = {}) {
		this.form = jQuery(formSelectors);
		this.input = this.form.find(inputSelectors);
		this.initiated = this.form.length && this.input;
		if (this.initiated) {
			const self = this;
			self.previousValue = self.input.val();

			this.input.on("input", function () {
				self.checkWithinRange(this, false);
			});

			this.input.on("blur", function () {
				self.checkWithinRange(this);
			});

			this.form.on("submit", function (e) {
				self.input.each(function () {
					if (!self.checkWithinRange(this)) {
						e.preventDefault();
					}
				});
			});
		}
	}

	checkWithinRange(input, replaceifNaN = true) {
		const number = parseInt(input.value);
		if (isNaN(number)) {
			input.value = replaceifNaN ? this.previousValue : input.value;
			return false;
		} else {
			if (number > 999) {
				alert("quantity can not exceed 999");
				input.value = this.previousValue;
				return false;
			} else if (number < 1) {
				alert("quantity can not be less than 1");
				input.value = 1;
				return false;
			}
			this.previousValue = input.value;
		}
		return true;
	}
}

export function initValidationQuantity() {
	new QuantityChecker({
		formSelectors: "form.woocommerce-cart-form, form.cart",
		inputSelectors: "input.qty"
	});
}
