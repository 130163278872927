import "waypoints/lib/noframework.waypoints";

class gifHoverAnimation {
	constructor(hvr) {
		this.hvr = $(hvr);
		// if the hvr element has the data-gif attribute, use it as the image to be animated. otherwise, find the child with the data-gif attribute.
		this.img = this.hvr.attr("data-gif") ? this.hvr : this.hvr.find("[data-gif]");
		this.original = this.img.attr("src");
		this.gif = this.img.attr("data-gif");

		this.hvr.on("mouseenter", () => {
			this.updateSrc(this.gif);
		});

		// if the hvr element has the data-hvrgif="permanent" attribute, the animation will not stop when the mouse leaves the element and reset to the original image.
		this.permanent = this.hvr.attr("data-hvrgif") === "permanent";
		if (!this.permanent) {
			this.hvr.on("mouseleave", () => {
				this.updateSrc(this.original);
			});
		}
	}

	updateSrc(url) {
		this.img.attr("src", `${url}?${Math.floor(Math.random() * 300 + 1)}`);
	}
}

export function initAnimations() {
	$("[data-hvrgif]").each(function () {
		new gifHoverAnimation(this);
	});

	$("[data-animate-icon]").each(function (i, element) {
		new Waypoint({
			element: element,
			handler: function () {
				const el = $(element);
				const animationName = el.attr("data-animate-icon");
				el.addClass(`animated ${animationName}`);
				this.destroy();
			},
			offset: "75%"
		});
	});
}
