import {Modal} from "../_classes";
import {Lightbox} from "../_classes";
import {throttle} from "../sf-utils";
import {reference} from "@popperjs/core";
import {delegate} from "tippy.js";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";

export function initOverlays() {
	new Modal(); // init modal

	// if lightbox elements exist...
	if ($("[data-lightbox-content], [data-lightbox]").length) {
		new Lightbox(); // ...init lightbox...

		// ...and init tooltips for lightbox:
		delegate("#js-app", {
			target: "[data-lightbox-content] img[src], [data-lightbox]",
			content: "Click to enlarge",
			theme: "sf-grey",
			arrow: false,
			offset: [0, 5],
			allowHTML: true,
			animation: "shift-away"
		});
	}

	//Show/hide back to top overlay when scrolled over 1vh:
	const backToTop = $("#sf-overlay-back-to-top");
	if (backToTop.length) {
		window.onscroll = throttle(function () {
			backToTop.showHide(window.scrollY >= window.innerHeight);
		}, 200);
	}

	//initiate tooltips:
	delegate("#js-app", {
		target: "[data-tippy-content]",
		theme: "sf-grey",
		arrow: false,
		offset: [0, 5],
		allowHTML: true,
		animation: "shift-away"
	});
	delegate("#js-app", {
		target: "[data-tippy-green-content]",
		content: (reference) => reference.getAttribute("data-tippy-green-content"),
		theme: "sf-green",
		arrow: false,
		offset: [0, 5],
		allowHTML: true,
		animation: "shift-away"
	});
}
