/**
 * @function string_to_array takes a string and splits into an array
 * @param {string} item - item to split into an array. If item passed that is not a string, it'll just be returned as is.
 * REQUIRED.
 * @param {string} splitBy - string to split the item by. OPTIONAL: defaults to ' '.
 * @returns {array} - item as an array, or as is if not passed as a string
 */
export function string_to_array(item, splitBy = " ") {
	if (item && typeof item === "string") {
		item = item.split(splitBy);
	}
	return Array.isArray(item) ? item : [item];
}
