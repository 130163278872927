import {FormValidator} from "../_classes";

export function initResetPassword() {
	// RESET PASSWORD VALIDATION
	new FormValidator(
		"resetpassword",
		{
			password_1: [
				"hasValue",
				"minLength8",
				"hasCapitalLetter",
				"hasLowercaseLetter",
				"hasNumber",
				"hasSpecialCharacter"
			],
			password_2: ["hasValue", "matchesDependant"]
		},
		{
			password_2: "password_1"
		},
		true
	);
}
