import {getHashFragment} from "../sf-utils";

/**
 * @class StateManager class to manage the state of a container using data attributes.
 * data-${name} is the container's id.
 * data-change-${name} is the button that can be clicked to change the state.
 * If no value set on data-change-${name}, the next state in the array will be set.
 * @param {string} name - The name of the container. Optional, defaults to "state".
 * @param {string[]} states - The states that can be set on the container.
 * Optional, defaults to either:
 * A. the states found in the DOM (values stored in data-change-[name] attributes),
 * B. ['true', 'false'] if no states are found in the DOM.
 * @param {function} before - The callback function to run before the state changes. Optional, defaults to an empty function.
 * @param {function} after - The callback function to run after the state changes. Optional, defaults to an empty function.
 * NB: Both callbacks receive (newState, oldState) as arguments.
 * @param {boolean} hashControl - Whether to control the state using the hash. Optional, defaults to false.
 */
export class StateManager {
	constructor({
		name = "state",
		states = [],
		before = () => {},
		after = () => {},
		hashControl = false
	} = {}) {
		this.name = name; // set the name
		this.$container = this._getContainer(); // set the container element (jQuery Object)
		this.callbacks = {before, after}; // set the callbacks
		this.hashControl = hashControl; // set the hash control

		this.addStates([
			...states, // add any states passed in
			this.getState(), // add the current state
			...this._getBtnStatesFromDOM() // add any other states found in buttons in the DOM
		]); // add the states to the state manager

		// set the initial state:
		this._setState(
			this._getOrSetHash() || // if there's a hash set, it's valid (in the states array) and hashControl is enabled (set to true), set the state to the hash...
				this.getState() || // ...else check if the state is set on the container in the DOM...
				this.states[0] // ...else set the initial state from the array
		);

		// add a click event listener to change the state:
		$(document).on("click", `[data-change-${this.name}]`, (e) => {
			e.preventDefault(); // Prevent the default action of the link
			const targetValue = this._getValueFromBtn(e.currentTarget); // Get the value from the button
			this.change(targetValue); // Change the state
		});
	}

	/** === PRIVATE METHODS === */

	/**
	 * if element(s) with data-[name] attribute can be found, return the first element, else return the body.
	 */
	_getContainer() {
		let container = $(`[data-${this.name}]`); // fetch all elements with data-[name] attribute
		return container.length ? container.first() : $("body"); // if any found, return the first, else return the body
	}

	/**
	 * If hashControl is enabled and the hash (provided as an argument or from the URL) is valid (in the states array), set the hash to match the state and return it.
	 * @param {boolean} hashControl - Whether to control the state using the hash.
	 * @returns {string} The state from the hash if it is valid, otherwise null.
	 */
	_getOrSetHash(hash = null) {
		if (!this.hashControl) return null; // If hash control is not enabled, return null
		hash = hash || getHashFragment(); // Get the hash from the URL if no argument provided

		// If the hash is valid (i.e. it's in the states array) set and return the hash:
		if (this.states.includes(hash)) {
			window.location.hash = hash; // Set the hash
			return hash; // Return the hash
		}
	}

	/**
	 * Get the value from the button.
	 * @param {HTMLElement} btn - The button element to get the value from.
	 * @returns {string} The value of the button.
	 */
	_getValueFromBtn(btn) {
		return $(btn).attr(`data-change-${this.name}`); // Get the value from the button using jQuery and .attr() method using the data-change-[name] attribute
	}

	/**
	 * Get other states stored in buttons (elements with data-change-[name] attribute) in the DOM.
	 * @returns {string[]} The states that can be set on the container.
	 */
	_getBtnStatesFromDOM() {
		let statesInDOM = []; // Create an array to store the states
		// Get all the buttons (elements with the data-change-[name] attribute) and loop through them:
		$(`[data-change-${this.name}]`).each((_, btn) => {
			const state = this._getValueFromBtn(btn); // Get the state value from the button
			statesInDOM = state ? [...statesInDOM, state] : statesInDOM; // Add the state to the array if it exists
		});
		return statesInDOM; // Return the states as an array
	}

	/**
	 * Set the state of the container.
	 * @param {string} state - The state to set.
	 */
	_setState(newState) {
		this.currentState = newState; // Set the current state
		this.$container.data(this.name, newState).attr(`data-${this.name}`, newState); // Set the state on the container
	}

	/** === PUBLIC METHODS === */

	/**
	 * Add states to the state manager.
	 * @param {string[]} states - The states to add.
	 */
	addStates(states) {
		states = typeof states === "string" ? [states] : states; // If the states is a string, convert it to an array
		states = this.states ? [...this.states, ...states] : states; // add any existing states to the new states, if there are any
		states = Array.from(new Set(states.filter((state) => state))); // Remove duplicates and empty strings, then set the states
		this.states = states.length ? states : ["true", "false"]; // set states if there are any, else set to the default of "true" and "false"
	}

	/**
	 * Change the state of the container.
	 * @param {string} state - The state to set. If not provided, the next state in the array will be set.
	 */
	change(state) {
		if (!state) {
			const currentIndex = this.states.indexOf(this.currentState); // Get the index of the current state in the states array
			const nextIndex = (currentIndex + 1) % this.states.length; // Get the index of the next state in the states array (using modulo to wrap around to 0 if at the end of the array)
			state = this.states[nextIndex]; // Set the state to the next state in the array
		}

		const oldState = this.currentState; // Get the current (soon to be old) state
		this.callbacks.before(state, oldState); // Call the before callback
		this._setState(state); // Set the state
		this._getOrSetHash(state); // Set the hash to the new state (if hashControl is enabled)
		this.callbacks.after(state, oldState); // Call the after callback
	}

	/**
	 * Get the current state of the container.
	 * @returns {string} The current state.
	 */
	getState() {
		return this.$container.data(this.name); // Get the current state from the container
	}
}
