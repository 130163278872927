/**
 * @function is_in_array checks if a value is in an array
 * @param {array} array - array to check
 * @param {*} check - value to check for
 * @returns TODO
 */
export function is_in_array(array, check) {
	let result;

	if (Array.isArray(check)) {
		result = true;
		for (const c of check) {
			result = !result ? result : array.indexOf(c) !== -1;
		}
	} else {
		result = array.indexOf(check) !== -1;
	}

	return result;
}
