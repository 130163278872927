import Snap from "snapsvg/dist/snap.svg.js";

export function initFooterFrog() {
	// Animate footer
	const paper = Snap("#frog-svg");
	const c = paper.path("M1180,480 C1180,480 1180,480 1180,480").attr({
		fill: "none",
		stroke: "#ff79e7",
		strokeWidth: 8
	});

	const circle = paper.circle(1180, 580, 0).attr({
		fill: "#ff79e7"
	});

	$(".footer-animation--inner img").click(function (e) {
		const fly = $(this);
		const yeah = $("footer").find("#frog-svg");
		const offset = yeah.offset();
		const relativeX = `M${e.pageX - offset.left},${e.pageY - offset.top}`;
		const Xaxis = e.pageX - offset.left;
		const Yaxis = e.pageY - offset.top;

		$(".footer-frog--mouth").addClass("open-mouth");
		$(".footer-frog--innerbg_eyes-left, .footer-frog--innerbg_eyes-right").removeClass(
			"animated-eyes"
		);

		fly.removeClass("animate-fly").css({
			left: Xaxis - fly.width(),
			top: Yaxis - fly.height()
		});

		setTimeout(() => {
			c.addClass("tongue");
		}, 100);

		setTimeout(() => {
			c.animate(
				{
					d: `${relativeX}C1180,480 1080,440 1180,580`
				},
				200
			).attr({
				fill: "none",
				stroke: "#ff79e7",
				strokeWidth: 8
			});
		}, 200);

		setTimeout(() => {
			c.animate(
				{
					d: "M1180,480 C1180,480 1180,480 1180,480"
				},
				200
			).attr({
				fill: "none",
				stroke: "#ff79e7",
				strokeWidth: 8
			});

			circle.animate(
				{
					cx: 1180,
					cy: 580,
					r: 0
				},
				200
			);
			fly.animate(
				{
					left: "1180px",
					top: "480px",
					opacity: "0"
				},
				400
			);

			setTimeout(() => {
				c.removeClass("tongue");
			}, 300);

			setTimeout(() => {
				fly.addClass("remove");
			}, 400);
		}, 400);

		setTimeout(() => {
			$(".footer-frog--mouth").removeClass("open-mouth");
		}, 600);
	});

	// Open frogs mouth on click
	$(".footer-frog--innerbg").click(() => {
		const frog = $(".footer-frog--mouth");

		frog.addClass("open-mouth");

		setTimeout(() => {
			frog.removeClass("open-mouth");
		}, 500);

		$("img.remove").addClass("reset-animation").removeClass("remove");

		$(".footer-animation--inner img").animate(
			{
				left: "50px",
				top: "50px"
			},
			1800
		);

		setTimeout(() => {
			$("img.reset-animation").removeClass("reset-animation").addClass("animate-fly");
		}, 2000);
	});

	$(document).on("click", ".footer-inner h3", function (e) {
		$(this).toggleClass("footer-head--visible");
	});
}
