import {smooth_scroll} from "../sf-utils";

export function initScrollListeners() {
	$("[data-scrollto]").on("click", function (e) {
		e.preventDefault();
		const scrollToTarget = $(this).attr("data-scrollto");
		smooth_scroll({
			target: `#${scrollToTarget}`,
			//back to top overrides the target when set to true:
			backToTop: !scrollToTarget || scrollToTarget === "top"
		});
	});
}
