import {EventDelegator} from "./EventDelegator";

function template(title, p) {
	return ` <h2>${title}</h2>
            <p>${p}</p>`;
}

/**
 * @class that handles a single accordion panel and its collaspe/rise transitions
 * @version 1.2
 */
class Panel_Transition {
	/**
	 * Class construction
	 * @param {String} id : #id of accordion panel to transition. Required.
	 * @return {Class} returns the class
	 */
	constructor(id = null) {
		this.init(id);
		return this;
	}

	init(id) {
		const self = this;
		self.init_settings(id);
	}

	init_settings(id) {
		const self = this;
		self.id = id;
		self.timeouts = [];
		self.panel = {};
		self.panel.head = self.cache_panel(self.id).head;
		self.panel.content = self.cache_panel(self.id).el;
		self.panel.height = self.cache_panel(self.id).height;
		self.transitioning = false;
		self.interval = 300;
		self.delay = 100; // allows DOM to update
		self.open = false;
		self.classes();
	}

	classes() {
		const self = this;
		self.classes = {
			show: "show-panel",
			transition: "panel-transitioning"
		};
	}

	/**
	 * Retrives panel and sets up config for change_panel_state()
	 */
	transition(callback = []) {
		const self = this;
		const open_panel = !self.panel.content.classList.contains(self.classes.show);

		// self.update_interval();
		if (callback.length) self.callback = callback;

		open_panel ? self.rise(open_panel) : !open_panel ? self.collapse(open_panel) : false;

		return this;
	}

	/**
	 * Reveals the panel content
	 * @param {Boolean} show_panel : show/hide panel
	 */
	rise(show_panel) {
		const self = this;
		self.change_panel_state(
			[
				{
					add: self.classes.transition
				}
			],
			show_panel
		);
		self.set_open(true);
	}

	/**
	 * Hides the panel content
	 * @param {Boolean} show_panel : show/hide panel
	 */
	collapse(show_panel) {
		const self = this;
		self.change_panel_state(
			[
				{
					add: self.classes.transition
				}
			],
			show_panel
		);
		self.set_open(false);
	}

	/**
	 * Changes the state of the panel open/close
	 * @param {Array} classes  : array of objects used to add/remove classes
	 * @param {Boolean} show_panel : show/hide panel
	 */
	change_panel_state(classes, show_panel) {
		const self = this;
		const panel = self.panel.content.parentElement;
		panel.classList.toggle("active");

		self.set_transitioning(true);
		self.check_content_height(show_panel);
		self.update_panel_classes(classes);
		self.update_panel_height(show_panel ? 0 : self.panel.height);
		self.start_transition(show_panel, self.update_panel_height.bind(self));
	}

	/**
	 * updates the panel height and then initialises monitor_transtion()
	 * @param {Boolean} show_panel : show/hide panel
	 * @param {Function} update_panel_height : kicks off the transition
	 */
	start_transition(show_panel, update_panel_height = null) {
		const self = this;
		if (update_panel_height) {
			self.push_timeout(self.delay, () => {
				update_panel_height(!show_panel ? 0 : self.panel.height);
			});
		}

		self.monitor_transtion(show_panel, self.interval, update_panel_height);
	}

	/**
	 * runs is_transition_finished() thats checks if the transtion is complete
	 * @param {Boolean} show_panel : show/hide panel
	 * @param {Number} interval  : how often to check transtion is complete.
	 * @param {Function} update_panel_height : kicked off transition
	 */
	monitor_transtion(show_panel, interval, update_panel_height, attempts = 1) {
		const self = this;

		interval = update_panel_height ? self.delay + interval : interval;

		self.push_timeout(interval, () => {
			self.is_transition_finished(show_panel, attempts);
		});
	}

	/**
	 * monitors the transtion and checks according to the interval
	 * @param {Number} interval  : checks if transtion is complete. Defaults to 25 milliseconds
	 * @param {Boolean} show_panel : show/hide panel
	 */
	is_transition_finished(show_panel, attempts = 1, interval = 25) {
		const self = this;
		const panel_height = self.panel.content.clientHeight;

		(show_panel && panel_height.toFixed(2) === self.panel.height.toFixed(2)) ||
		(!show_panel && panel_height === 0)
			? self.transition_complete(show_panel)
			: attempts > 1
			? self.transition_complete(show_panel)
			: self.monitor_transtion(show_panel, interval, null, attempts + 1);
	}

	/**
	 * When transition is complete add/remove classes/style attribute and clear timeouts
	 * @param {Boolean} show_panel : show/hide panel
	 */
	transition_complete(show_panel) {
		const self = this;

		show_panel
			? self.update_panel_classes([
					{
						add: self.classes.show
					},
					{
						remove: self.classes.transition
					}
			  ])
			: self.update_panel_classes([
					{
						remove: self.classes.transition
					},
					{
						remove: self.classes.show
					}
			  ]);

		self.update_style_attributes();

		if (show_panel && self.callback && self.callback.length) {
			self.callback.forEach((cb) => {
				cb(this.panel);
			});
		}

		const panel = self.panel.content.parentElement;
		panel.classList.toggle("open");

		self.clearTimeouts();
		self.set_transitioning(false);
	}

	/**
	 * pushes timeouts to this.timeouts
	 */
	push_timeout(interval, callback) {
		const self = this;
		self.timeouts.push(
			setTimeout(() => {
				callback();
			}, interval)
		);
	}

	/**
	 * Clear timeouts
	 */
	clearTimeouts() {
		const self = this;
		for (const to in self.timeouts) {
			clearTimeout(self.timeouts[to]);
		}
		self.timeouts = [];
	}

	/**
	 * Adds style attributes to the panel depending on if its state (open/closed)
	 */
	update_style_attributes() {
		const self = this;
		!self.is_open()
			? self.update_style_attribute({
					display: "",
					height: ""
			  })
			: self.update_style_attribute({
					height: ""
			  });
	}

	/**
	 * Update panel style attributes
	 * @param {String} prop : style property to update
	 */
	update_style_attribute(props = {}) {
		for (const prop in props) {
			this.panel.content.style[prop] = props[prop];
		}
	}

	/**
	 * Update panel classes by order of the array
	 * @param {Array} classes: array of objects that cotina the classes
	 */
	update_panel_classes(classes) {
		for (const classet of classes) {
			this.update_panel_class(classet);
		}
	}

	/**
	 * add/remove panel classes
	 * @param {Object} classet : the property sets the method and its value the class
	 */
	update_panel_class(classet) {
		for (const method in classet) {
			this.panel.content.classList[method](classet[method]);
		}
	}

	/**
	 * Cache panel element and panel content height
	 * @param {String} id : DOM element id to transition
	 */
	cache_panel(id) {
		const self = this;
		self.panel.head
			? self.panel.head
			: (self.panel.head = document.querySelector(`[data-accordion-id="${id}"]`));
		self.panel.content ? self.panel.content : (self.panel.content = document.querySelector(id));
		self.panel.height = self.panel.content.firstElementChild.clientHeight;
		return self.panel;
	}

	/**
	 * Update panel height
	 * @param {Number} height : height to transtion panel too
	 */
	update_panel_height(height = this.panel.height) {
		this.update_style_attribute({
			height: `${height}px`
		});
	}

	/**
	 * @prop {Boolean} is_open : sets this.open to true/false
	 */
	set_open(is_open) {
		this.open = is_open;
	}

	/**
	 * @return {Boolean}
	 */
	is_open() {
		return this.open;
	}

	/**
	 * @prop {Boolean} transitioning : sets this.transitioning to true/false
	 */
	set_transitioning(transitioning) {
		this.transitioning = transitioning;
	}

	/**
	 * @return {Boolean}
	 */
	is_transitioning() {
		return this.transitioning;
	}

	/**
	 * Checks the panels content height and stores the value in self.panel.height
	 */
	check_content_height(show_panel) {
		const self = this;
		show_panel
			? self.update_style_attribute({
					display: "block"
			  })
			: false;
		self.panel.height = Number(self.cache_panel(self.id).height.toFixed(2));
	}

	update_interval(delay = this.delay) {
		this.interval = this.get_transition_duration(delay);
	}

	/**
	 * Gets the transition duration
	 * @prop {Number} delay : delay to allow for transition to finish
	 */
	get_transition_duration(delay) {
		const self = this;
		const style_sheets = document.styleSheets;
		const default_duration = 310;

		const css_rule = self.check_stylesheets(style_sheets);

		return self.calculate_transition_duration(css_rule, default_duration, delay);
	}

	calculate_transition_duration(css_rule, default_duration, delay) {
		let duration;
		if (css_rule) {
			duration = css_rule.style.transitionDuration.substring(
				0,
				css_rule.style.transitionDuration.length - 1
			);
			duration = duration ? parseFloat(Number(duration)) * 1000 + delay : default_duration;
		} else {
			duration = default_duration;
		}
		return duration;
	}

	check_stylesheets(style_sheets) {
		let css_rule;
		for (const ss of style_sheets) {
			try {
				css_rule = this.check_css_rules(ss.cssRules);
				if (css_rule) {
					break;
				}
			} catch (e) {
				// do nothing
			}
		}
		return css_rule;
	}

	check_css_rules(cssRules) {
		for (const rule of cssRules) {
			if (rule.selectorText === ".accordion__panel--content.panel-transitioning") {
				return rule;
			}
		}
	}
}

/**
 * @class that creates and triggers accordion events
 */
export class Accordion {
	/**
	 * Class construction
	 * @param {String} selector : element atribute used to cache accordion. Required.
	 * @param {Boolean} create_accordion : default false
	 */
	constructor({
		selector = "[data-accordion]",
		create_accordion = false,
		single_panel = false,
		callback = null,
		extend_events = null
	} = {}) {
		this.init(selector, create_accordion, single_panel, callback, extend_events);
	}

	init(selector, create_accordion, single_panel, callback, extend_events) {
		const self = this;
		self.extend_events = extend_events;
		self.selector = selector;
		self.accordion = document.querySelector(self.selector);
		self.single_panel = single_panel;
		self.panels = {};
		self.prev_panel_id;
		self.callback = callback;
		self.build_accordion(create_accordion);
		if (self.accordion) {
			self.init_event_listener();
		}
	}

	/**
	 * Binds event listener to the accordion
	 */
	init_event_listener() {
		const self = this;
		const options = {
			selector: self.selector,
			eventType: "click",
			events: {
				accordionId: (e, d) => {
					this.transition_panel(d.accordionId);
				}
			}
		};

		if (self.extend_events) {
			for (const obj of self.extend_events) {
				options.events[obj.key] = obj.callback;
			}
		}
		const events = new EventDelegator({delegators: [options]});
	}

	/**
	 * Manages the transition of the accordion panels
	 * @prop {String} id : panel id
	 */
	transition_panel(id, callback) {
		const self = this;

		self.add_panel_transition(id);

		const transition_clicked_panel = self.previous_panel(id);

		if (transition_clicked_panel) {
			const c = callback ? [...callback] : [];
			self.panels[id].transition(self.callback ? [self.callback, ...c] : [...c]);
			self.update_previous_panel_id(id);
		}
	}

	/**
	 * Manages the transition of the accordion panels
	 * @prop {String} id : panel id
	 */
	add_panel_transition(id) {
		const self = this;

		if (!self.panels[id]) {
			self.panels[id] = new Panel_Transition(id);
		}
	}

	previous_panel(id) {
		const self = this;
		return self.single_panel
			? self.check_state_of_previous_panel(id)
			: !self.panels[id].is_transitioning();
	}

	check_state_of_previous_panel(id) {
		const self = this;
		const prev_panel = self.panels[self.prev_panel_id];
		let transition_clicked_panel = true;

		if (prev_panel) {
			const is_transitioning = prev_panel.is_transitioning();

			is_transitioning
				? (transition_clicked_panel = false)
				: self.prev_panel_id !== id && prev_panel.is_open()
				? prev_panel.transition() // close previous panel if open
				: false;
		}

		return transition_clicked_panel;
	}

	update_previous_panel_id(id) {
		this.prev_panel_id = id;
	}

	// WIP - TODO Build accordion from dataset
	build_accordion(create_accordion) {
		if (create_accordion) {
			let i;
			for (i = 1; i < 10; i++) {
				const panel = this.build_accordion_panels(i);
				this.accordion.appendChild(panel);
			}
		}
	}

	build_accordion_panels(i) {
		const accordion_panel = this.create_accordion_panel("accordion__panel");

		const accordion_panel_header = this.create_accordion_panel_header(
			"accordion__panel--header",
			{
				attribute: "data-accordion-id",
				value: `#panel${i}`
			},
			"section toggle"
		);

		const accordion_panel_content = this.create_accordion_panel_content(
			"accordion__panel--content",
			`panel${i}`
		);

		accordion_panel.append(accordion_panel_header);
		accordion_panel.append(accordion_panel_content.returnElement());

		return accordion_panel.returnElement();
	}

	create_accordion_panel(classes) {
		return new Element({
			classes: [classes]
		});
	}

	create_accordion_panel_header(classes, attributions, content) {
		return new Element({
			classes: [classes],
			attributions
		})
			.updateHTMLOfElement(content)
			.returnElement();
	}

	create_accordion_panel_content(classes, id) {
		const accordion_panel_content = new Element({
			classes: [classes],
			id
		});
		const accordion_panel_content_body = this.create_accordion_panel_content_body(
			"accordion__panel--content-body",
			template("h1", "paddingBottom ")
		);
		accordion_panel_content.append(accordion_panel_content_body);
		return accordion_panel_content;
	}

	create_accordion_panel_content_body(classes, content) {
		return new Element({
			classes: [classes]
		})
			.updateHTMLOfElement(content)
			.returnElement();
	}
}
