import {matchLocationPath} from "../sf-utils";

class ContactPageContactForm {
	constructor() {
		this.servicesRequiredButton = $("#service-required--button"); // cache the button for 'services required'
		this.servicesRequiredOverlay = $("#sf-service-required"); // cache the overlay for 'services required'
		this.ServicesRequired = false; // setup var to store number of services required. False on First Page Load
		this.servicesRequiredButtonText = this.getTextForServicesRequiredButton(); // grab the text for the button
		this.setupEventHandlers(); // setup event handlers
	}

	/**
	 * @method refreshServicesRequired - count the number of checked checkboxes in the form, and store the result.
	 */
	refreshServicesRequired() {
		this.ServicesRequired = $('#sf-service-required input[type="checkbox"]:checked').length; // jquery selector to count the number of checked checkboxes
		this.servicesRequiredButtonText = this.getTextForServicesRequiredButton(); // set the text for the button
	}

	/**
	 * @method getTextForServicesRequiredButton - returns the text for the services required button based on the number of services selected
	 * @returns {string} - returns the text for the services required button based on the number of services selected
	 */
	getTextForServicesRequiredButton() {
		return !this.ServicesRequired // if no services selected...
			? "Select service(s) required" // ... prompt user to select services...
			: this.ServicesRequired === 1 // ...if 1 service selected...
			? "1 service selected" // ...display singular message...
			: `${this.ServicesRequired} services selected`; // ...otherwise display plural message
	}

	/**
	 * @method updateContactFormButton - updates the contact form button based on the number of services selected
	 */
	updateContactFormButton() {
		// update the number of services required:
		this.refreshServicesRequired();

		// set timeout:
		const timeout = !this.ServicesRequired // if no services selected...
			? 300 // ...delay message update by 300ms to allow for overlay animation before highlighting button red...
			: 0; // ...otherwise update message immediately

		// update button:
		setTimeout(() => {
			this.servicesRequiredButton // grab the cached button...
				.text(this.servicesRequiredButtonText) // ...update button text...
				.addRmvClass("wpcf7-not-valid", !this.ServicesRequired); // ...add 'wpcf7-not-valid' class if no services selected (to highlight button red)
		}, timeout); // apply timeout if required
	}

	/**
	 * @method setupEventHandlers - setup event handlers for the contact us page contact form
	 */
	setupEventHandlers() {
		//service required overlay:
		$(document).on("click", "[data-servicerequired]", (e) => {
			// prevent default action:
			e.preventDefault();

			// show/hide the service required overlay:
			this.servicesRequiredOverlay.showHide();

			// update the contact form button based on the number of services selected:
			this.updateContactFormButton();
		});

		// handle errors on contact form submission from contact form 7 plugin:
		$(document).on("wpcf7invalid", (e) => {
			this.updateContactFormButton(); // update the contact form button if there are errors relating to the services required
		});
	}
}

export function initContactForms() {
	/**
	 * ===============================================================
	 * Handle file uploads on contact forms (e.g. CV upload)
	 * ===============================================================
	 */

	// handle file uploads on contact forms:
	$(document).on("change", "[data-upload] input", function () {
		const input = $(this); // cache the input
		const filename = input.val().replace(/C:\\fakepath\\/i, ""); // grab and sanitize the filename from the input
		input
			.closest("[data-upload]") // grab the closest label element with the 'data-upload' attribute
			.attr("data-upload", filename) // update the 'data-upload' attribute with the filename
			.addClass("sf-upload-input--uploaded"); // add the 'sf-upload-input--uploaded' class to the label
	});

	/**
	 * ===============================================================
	 * Handle Contact Us page form
	 * ===============================================================
	 */

	// if on the contact us page:
	if (matchLocationPath("/contact-us/")) {
		new ContactPageContactForm(); // instantiate the ContactPageContactForm class
	}
}
