/**
 * @function throttle throttle EventListners by defferring payload
 * @param {Function} cb - function to execute every x interval
 * @param {Number} delay - sets the execution intervals
 * @return { Function } - function to execute on event
 */
export function throttle(cb, delay = 200) {
	let wait_for_it = false;

	return (e) => {
		if (!wait_for_it) {
			cb(e);
			wait_for_it = true;
			setTimeout(() => {
				wait_for_it = false;
			}, delay);
		}
	};
}
