// TODO BP: look at replacing with https://vestjs.dev/

export class FormValidator {
	/**
	 * @constructor - sets up the form cache, fields, dependent fields and pseudo submit button
	 * @param {string} formName - the name of the form.
	 * Required.
	 * @param {*} fields - the fields object (names of fields to run checks on as keys, with the checks for each field as the prop; each check as a string in an array).
	 * Required.
	 * @param {Object} dependentFields - the dependent fields object (names of fields as keys, with the name of the field it is dependent on as the value).
	 * @param {Boolean} pseudoSubmit - whether to set up a pseudo submit button (i.e a button to click to run validation before the actual submit button is clicked).
	 */
	constructor(formName, fields, dependentFields = false, pseudoSubmit = true) {
		this.form = this.setupFormCache(formName); // set up the form cache (i.e. get the form element from the DOM and information about the form to be used later)

		// if successfully retrieved the form element:
		if (this.form) {
			// set any dependent fields, or an empty object if none are provided:
			this.dependentFields = dependentFields || {};
			// set up the keys object: a set of functions to get the required elements from the DOM (amoung some other things) and update to the this.fields cache if applicable:
			this.keys = {
				// get the container element for the field (i.e. the parent element of the input)
				// & reset the severity to -1 (i.e. neutral) on the container element:
				container: (name) => {
					const container = this.form.element.find(`#${name}_field, #${formName}_${name}_field`);
					container.attr("data-severity", -1);
					return container;
				},
				// get the input element for the field:
				input: (name) => {
					return this.form.element.find(`[name="${name}"]`);
				},
				// get the label element for the field:
				label: (name) => {
					return this.getFieldItem(name, "container").find(
						`label[for="${name}"], label[for="${formName}_${name}"]`
					);
				},
				// create a message element for the field and add it to the DOM after the field label element:
				messageElement: (name) => {
					const container = this.getFieldItem(name, "container"),
						label = this.getFieldItem(name, "label"),
						messageElement = $('<div style="display:none;" class="checkout__message"></div>');
					container.find(".checkout__message").remove();
					label.after(messageElement);
					return messageElement;
				},
				// get the current value of the input (or reset the value if a value is set to true or an empty string):
				value: (name, value = false) => {
					const input = this.getFieldItem(name, "input");
					if (value || value === "") {
						input.val("");
					}
					return input.val();
				},
				// check if the field is required (i.e. it's visible (not hidden by WooCommerce) & has a class of 'validate-required' or is the tax_id field):
				required: (name) => {
					const container = this.getFieldItem(name, "container");
					const visible = container.is(":visible");
					const required = name === "tax_id" || container.hasClass("validate-required");
					return visible && required;
				},
				// reset error log for the field:
				errors: (name) => {
					return [];
				},
				// get the dependent field information (name, field, if it has a value set) for a field (that is dependent on another field):
				dependent: (name) => {
					const dependent = this.dependentFields[name],
						field = this.fields[dependent];
					return dependent
						? {
								name: dependent,
								field,
								hasValue: () => {
									return this.checks.hasValue(field).result;
								}
						  }
						: false;
				},
				// check if the field is the dependent of another field:
				dependentOf: (name) => {
					for (const [field, dependent] of Object.entries(this.dependentFields)) {
						if (dependent === name) {
							return field;
						}
					}
					return false;
				}
			};

			// set up the checks object, a set of functions to run checks on the fields:
			// N.B All return an object with a result (true/false), a message and the name of the check that was run.
			this.checks = {
				// check if the field has a value:
				hasValue: (field) => {
					return {
						result: Boolean(field.value && field.value !== ""),
						msg: "this field must be completed",
						check: "hasValue"
					};
				},
				// check if the field has a minimum length of 8 characters:
				minLength8: (field) => {
					return {
						result: Boolean(field.value.length >= 8),
						msg: "this field needs at least 8 characters",
						check: "minLength8"
					};
				},
				// check if the Tax ID field has a value, or the checkbox (to confirm no tax ID) is checked:
				hasCompletedTaxField: (field) => {
					return {
						result: Boolean(
							(this.checks.hasValue(field).result && !field.checkbox.is(":checked")) ||
								(!this.checks.hasValue(field).result && field.checkbox.is(":checked"))
						),
						msg: "enter a Tax ID or confirm you do not have a tax ID",
						check: "hasCompletedTaxField"
					};
				},
				// check if the field has a capital letter:
				hasCapitalLetter: (field) => {
					return {
						result: field.value.match(/[A-Z]/),
						msg: "this field must contain at least 1 capital letter",
						check: "hasCapitalLetter"
					};
				},
				// check if the field has a lowercase letter:
				hasLowercaseLetter: (field) => {
					return {
						result: field.value.match(/[a-z]/),
						msg: "this field must contain at least 1 lowercase letter",
						check: "hasLowercaseLetter"
					};
				},
				// check if the field has a special character:
				hasSpecialCharacter: (field) => {
					return {
						result: field.value.match(/[~`!@#£$%^&*()_+={}|/:;"'<>,.?\-\[\]\\]/g),
						msg: "this field must contain at least 1 special character",
						check: "hasSpecialCharacter"
					};
				},
				// check if the field has a number:
				hasNumber: (field) => {
					return {
						result: field.value.match(/\d/),
						msg: "this field must contain at least 1 number",
						check: "hasNumber"
					};
				},
				// check if the field is a valid email address:
				isEmail: (field) => {
					return {
						result: Boolean(
							field.value
								.toLowerCase()
								.match(
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
								)
						),
						msg: "this field should be an email address",
						check: "isEmail"
					};
				},
				// check if the field is NOT an email address. I.e for username fields:
				noEmail: (field) => {
					return {
						result: !this.checks.isEmail(field).result,
						msg: "this field cannot be an email address",
						check: "noEmail"
					};
				},
				// check if the field is AlphaNumeric and lowercase (mostly for username fields):
				isLowercaseAlphaNumeric: (field) => {
					return {
						result: field.value.match(/^[a-z0-9]+$/),
						msg: "field can only contain lowercase letters & numbers",
						check: "isLowercaseAlphaNumeric"
					};
				},
				// check if the field has at least 4 letters:
				has4Letters: (field) => {
					return {
						result: field.value.match(/^([^a-z]*[a-z]){4}[\s\S]*/),
						msg: "field must be have at least 4 lowercase letters",
						check: "has4Letters"
					};
				},
				// check no spaces in the field value:
				noSpaces: (field) => {
					return {
						result: !field.value.match(/\s/g),
						msg: "this field cannot contain spaces",
						check: "noSpaces"
					};
				},
				// check if the field value is under 30 characters:
				maxLength30: (field) => {
					return {
						result: Boolean(field.value.length <= 30),
						msg: "this field cannot be more than 30 characters long",
						check: "maxLength30"
					};
				},
				// check if the field value matches the value of another field (E.g password/email confirmation):
				matchesDependant: (field) => {
					return {
						result: field.dependent
							? field.value === this.fields[field.dependent.name].value
							: true,
						msg: `this field must match ${field.dependent.name} field`,
						check: "matchesDependant"
					};
				},
				// check if the field is filled and validated
				completed: async (name, field = this.fields[name]) => {
					if (
						await this.validateFields({
							fields: [name],
							processErrors: true,
							severity: 1
						})
					) {
						if (field.dependentOf) {
							this.refreshFields({
								names: [field.dependentOf],
								resetValue: true
							});
						}
						return {
							result: true,
							msg: "looks good",
							check: "completed"
						};
					}
				}
			};
			// set up the fields object:
			this.fields = this.setupFields(fields);
			// set up the submit button and a pseudo submit button, if pseudoSubmit is true:
			if (pseudoSubmit) {
				this.buttons = this.setupButtons();
			}
		}
	}

	/**
	 * @method setupButtons - sets up the submit button and a pseudo submit button
	 * @returns {{submit: JQuery<HTMLElement>, pseudo: JQuery<HTMLElement>}}
	 */
	setupButtons() {
		// cache the contexts:
		const self = this,
			submit = this.form.element.find("button[type=submit]"),
			pseudo = submit.clone().addClass("pseudo-submit").attr("type", "button");
		// disable the submit button and hide it:
		submit.attr("disabled", "disabled").hide();
		// insert the pseudo submit button before the submit button:
		pseudo.insertAfter(submit);

		// add a click event to the pseudo submit button:
		pseudo.click(async function () {
			const valid = await self.validateFields(); // validate the fields
			// if valid ...
			valid
				? submit.removeAttr("disabled").click() // ... enable the submit button and click it ...
				: submit.attr("disabled", "disabled"); // ... else disable the submit button.
		});

		// return the submit and pseudo submit buttons in an object:
		return {
			submit,
			pseudo
		};
	}

	/**
	 * @method setupFormCache - sets up the form cache to reference throughout the class
	 * @param {string} name - the name of the form
	 * @returns {boolean|{name: string, query: string, element: JQuery<HTMLElement>}}
	 */
	setupFormCache(name) {
		// get/set the cache items:
		const query = `form[name="${name}"]`,
			element = $(query);
		return element.length // if the form exists (i.e jquery object has length)...
			? {name, query, element} // ... return the form cache object ...
			: false; // ... else return false.
	}

	/**
	 * @method validateFields - validates the field(s) based on the checks attached to them
	 * @param {Array} fields - an array of fields to validate.
	 * Optional. If not provided, all fields attached to this class will be validated.
	 * @param {boolean} processErrors - whether to process errors - if unprocessed they are just added to the log to be processed later.
	 * Optional. Default is true.
	 * @param {boolean} returnOnFirstError - whether to return on the first error.
	 * Optional. Default is false.
	 * @param {number} severity - the severity of the error:
	 * 2 = Error (red)
	 * 1 = Warning (orange)
	 * 0 = Neutral (grey)
	 * -1 = Confirmation (green).
	 * Optional. Default is 2 - Error.
	 * @returns {boolean} - whether the field(s) are valid or not.
	 */
	async validateFields({
		fields = Object.keys(this.fields),
		processErrors = true,
		returnOnFirstError = false,
		severity = 2
	} = {}) {
		let invalid = false; // initiate with invalid as false
		this.refreshFields({names: fields, keys: ["errors", "value"]}); // refresh the fields (i.e. clear the errors and get the latest values)
		// Loop through the fields:
		for (const name of fields) {
			const item = this.fields[name]; // store the field in a variable for easy reference
			// if the field is required or has a value ...
			if (item.required || this.checks.hasValue(item).result) {
				// ... loop through the checks ...
				for (let check of item.checks) {
					check = this.checks[check](item); // ... and run the check ...
					// ... if the check fails:
					if (!check.result) {
						if (returnOnFirstError) {
							return false; // exit the method and prevent all further processing, if returnOnFirstError is true.
						}
						this.addErrorToLog({error: check, field: item}); // add the error to the error log for this field
					}
					// otherwise (i.e if the check passes), do nothing!
				}
			}
			// if processErrors is true, process the error logs:
			if (processErrors) {
				this.processErrorLogs({name, recheck: false, severity});
			}
			// if invalid has been set to true on previous fields, keep it set to true. Otherwise check if the current field has errors, and set invalid to true if it does.
			invalid = invalid || item.errors.length > 0;
		}

		// return true (i.e is valid) if invalid is false, otherwise return false (i.e is not valid).
		return !invalid;
	}

	/**
	 * @method addErrorToLog - adds an error to the error log for the field
	 * @param {string} name - the name of the field.
	 * Optional. If not provided, the 'field' parameter will need to be.
	 * @param {object} error - the error object (i.e. {result: boolean, msg: string, check: string (method reference)}).
	 * Required.
	 * @param {object} field - the field object (i.e. this.fields[name]).
	 * Optional. If not provided, the 'name' parameter will need to be: the field will be retrieved from the fields object using the 'name' argument.
	 */
	addErrorToLog({name, error, field = this.fields[name]} = {}) {
		field.errors = field.errors.includes(error) // if the error is already in the field errors array...
			? field.errors // ... do nothing (set the field.errors to itself) ...
			: [...field.errors, error]; // ... else add the error to the field errors array.
	}

	/**
	 * @method processErrorLogs - processes the error logs for the field
	 * @param {string} name - the name of the field.
	 * Optional. If not provided, the 'field' parameter will need to be.
	 * @param {object} field - the field object (i.e. this.fields[name]).
	 * Optional. If not provided, the 'name' parameter will need to be: the field will be retrieved from the fields object using the 'name' argument.
	 * @param {object} error - the error object (i.e. {result: boolean, msg: string, check: string (method reference)}).
	 * Optional. If not provided, will use the first error in the field.errors array (if there is anything in the array), or be set to null.
	 * @param {boolean} recheck - whether to recheck the field after processing the error logs.
	 * Optional. Default is true.
	 * @param {number} severity - the severity of the error:
	 * 2 = Error (red)
	 * 1 = Warning (orange)
	 * 0 = Neutral (grey)
	 * -1 = Confirmation (green).
	 * Optional. Default is 1 - Warning.
	 * @returns
	 */
	processErrorLogs({
		name,
		field = this.fields[name],
		error = field.errors[0],
		recheck = true,
		severity = 1
	} = {}) {
		// if there is an error and recheck is true ...
		if (error && recheck) {
			this.refreshFields({names: [name], keys: ["value"]}); // ... refresh the field ...
			// ... and recheck the field using the check that caused the error. If the check passes ...
			if (this.checks[error.check](field).result) {
				field.errors = field.errors.filter((item) => {
					return item !== error; // ... remove the error from the field errors log array by filtering it out ...
				});
				field.errors.length // ... if there are more errors ...
					? this.processErrorLogs({name, recheck, severity}) // ... rerun this function with the same parameters to process other errors in the log ...
					: this.processErrorLogs({
							name,
							recheck: false,
							severity: 0,
							error: this.checks.completed(name)
					  }); // ... otherwise, process the completion of the field. (i.e rerun this function with recheck set to false and severity set to 0 - neutral).
				return field.errors.length; // return the number of errors in the field errors array and prevent further execution, i.e don't add messages to the field.
			}
		}
		// if there is no error, or recheck is false, or the check fails, add the message to the field:
		this.addMessageToField(name, error ? error.msg : "", severity);
		return field.errors.length; // return the number of errors in the field errors array. if there are no errors, this will be 0 (i.e false).
	}

	/**
	 * @method addMessageToField - adds a message to the field
	 * @param {string} name - the name of the field.
	 * Optional. If not provided, the 'field' parameter will need to be.
	 * @param {string} message - the message to add to the field.
	 * Optional. Default is an empty string.
	 * @param {number} severity - the severity of the message:
	 * 2 = Error (red)
	 * 1 = Warning (orange)
	 * 0 = Neutral (grey)
	 * -1 = Confirmation (green).
	 * Optional. Default is 2 (error).
	 * @param {Object} field - the field object (i.e. this.fields[name]).
	 * Optional. If not provided, the 'name' parameter will need to be: the field will be retrieved from the fields object using the 'name' argument.
	 * @param {jQueryObject} messageElement - the message element (i.e. this.fields[name].messageElement).
	 * Optional. If not provided, the message element will be retrieved from the field object.
	 * @param {jQueryObject} container  - the container element (i.e. this.fields[name].container).
	 * Optional. If not provided, the container element will be retrieved from the field object.
	 */
	addMessageToField(
		name,
		message = "",
		severity = 2,
		field = this.fields[name],
		messageElement = field.messageElement,
		container = field.container
	) {
		// if anything is going to change, reset. I.e:
		if (
			message === "" || // if the message is empty ...
			messageElement.text() !== message || // ... or the message is different to the current message ...
			container.attr("data-severity") != severity // ... or the severity is different to the current severity ...
		) {
			container.attr("data-severity", -1); // ... set the container severity to -1 (i.e. neutral) ...
			messageElement.hide(); // ... and hide the message element ...
		}

		// if the message is not empty ...
		if (message) {
			messageElement.html(message); // ... add the message to the message element ...
			container.attr("data-severity", severity); // ... and set the container severity to the severity provided ...

			setTimeout(() => {
				messageElement.show(); // ... then (re)show the message element after a short delay.
			}, 50);
		}
	}

	/**
	 * @method getFieldItem - gets an item from the field object or runs a function to get the required item
	 * @param {string} name - the name of the field.
	 * @param {string} item - the item to get from the field object.
	 * @returns
	 */
	getFieldItem(name, item) {
		return this.fields && this.fields[name] && this.fields[name][item] // if the fields object exists, and the field exists, and the item exists ...
			? this.fields[name][item] // ... return the item from the field ...
			: this.keys[item](name); // ... otherwise, run a function (stored in this.keys object) that will return the required item.
	}

	/**
	 * @method setupFields - sets up the fields object
	 * @param {Object} fields - the fields object
	 * @returns {Object}
	 */
	setupFields(fields) {
		this.fields = {}; // initiate the fields object as an empty object
		// loop through the fields object and set up the fields:
		for (const [name, checks] of Object.entries(fields)) {
			// Dynamically constructs and assigns an object to this.fields[name] based on the keys and functions defined in this.keys.
			this.fields[name] = Object.assign(
				// Use spread syntax to expand the array resulting from the map operation into individual arguments for Object.assign.
				...Object.keys(this.keys).map((key) => ({
					// For each key in this.keys, create a new object with a single property.
					// The property name is the current key, and its value is computed by calling the corresponding function in this.keys with 'name' as the argument.
					[key]: this.keys[key](name)
				}))
			);
			// Add the checks to the field object, if they exist, else set the checks to an empty array:
			this.fields[name]["checks"] = checks || [];
			// If the field has checks, attach events to the inputs:
			if (checks && checks.length) {
				this.attachEventsToInputs(name);
			}
		}
		return this.fields; // return the fields object that has now been set up.
	}

	/**
	 * @method attachEventsToInputs - attaches events to the inputs in the form
	 * @param {string} name - the name of the field.
	 * Optional. If not provided, the 'field' parameter will need to be.
	 * @param {*} field - the field object (i.e. this.fields[name]).
	 * Optional. If not provided, the 'name' parameter will need to be: the field will be retrieved from the fields object using the 'name' argument.
	 */
	attachEventsToInputs(name, field = this.fields[name]) {
		const self = this; // cache the context
		// detect 'input' events (i.e user typing in an input) on the input field
		// N.B this is attached to the body to allow for dynamic form elements (WooCommerce will add/remove fields):
		$("body").on("input", `${self.form.query} [name="${name}"]`, function () {
			// if the field is not dependent on another field, or the dependent field is complete, run the onInput method for the field:
			if (!field.dependent || field.dependent.complete) {
				self.onInput(name); // (field name is passed as an argument to target the correct onInput function(s) set on that field)
			}
		});
		// detect 'blur' events (i.e user moving away from the input) on the input field
		// N.B this is attached to the body to allow for dynamic form elements (WooCommerce will add/remove fields):
		$("body").on("blur", `${self.form.query} [name="${name}"]`, function () {
			// if the field is not dependent on another field, or the dependent field is complete, run the onLeave method for the field:
			if (!field.dependent || field.dependent.complete) {
				self.onLeave(name); // (field name is passed as an argument to target the correct onLeave function(s) set on that field)
			}
		});

		// if the field is dependent on another field...
		if (field.dependent) {
			// ... attach a 'focus' (i.e user entering an input) event to the current field input
			// N.B this is attached to the body to allow for dynamic form elements (WooCommerce will add/remove fields) ...
			$("body").on("focus", `${self.form.query} [name="${name}"]`, function () {
				// ... and run the checkDependant method for the field when 'focussed':
				self.checkDependant(name); // (field name is passed as an argument to target the correct checkDependant function(s) set on that field)
			});
		}
	}

	/**
	 * @method refreshFields - refreshes the field(s) i.e. updates the object in the this.fields cache (useful for clearing errors, getting the latest values and/or returning new/replaced elements in the DOM that might have been added/removed by WooCommerce)
	 * @param names - the names of the fields to refresh.
	 * Optional. If not provided, all fields attached to this class will be refreshed.
	 * @param keys - the keys to refresh.
	 * Optional. If not provided, all keys will be refreshed.
	 * @param resetValue - whether to reset the value of the field.
	 * Optional. Default is false.
	 */
	refreshFields({
		names = Object.keys(this.fields),
		keys = Object.keys(this.keys),
		resetValue = false
	} = {}) {
		// Loop through the names:
		for (let n of names) {
			// get the field from the name:
			const field = this.fields[n];
			// Loop through the keys:
			for (const k of keys) {
				// set the field key to the result of the function in this.keys with the current field name and resetValue as arguments:
				field[k] = this.keys[k](n, resetValue);
			}
		}
	}

	/**
	 * @method checkDependant - checks if the dependent field is complete
	 * @param {string} name - the name of the field.
	 * Optional. If not provided, the 'field' parameter will need to be.
	 * @param {Object} field - the field object (i.e. this.fields[name]).
	 * Optional. If not provided, the 'name' parameter will need to be: the field will be retrieved from the fields object using the 'name' argument.
	 * @param {*} dependent - the dependent field object (attached to the 'field' object).
	 * Optional. Will be set to the 'field.dependent' object if not provided.
	 */
	async checkDependant(name, field = this.fields[name], dependent = field.dependent) {
		// determine if the dependent field is complete ...
		dependent.complete = !dependent.hasValue() // ... if the dependent field has no value ...
			? false // it can't be complete, so set it to false ...
			: await this.validateFields({
					fields: [dependent.name],
					processErrors: true,
					severity: 2
			  }); // ... otherwise await validation of the dependent field and set the result (true/false)

		// if the dependent field is not complete (i.e has no value, or has errors) then:
		if (!dependent.complete) {
			// focus on the dependent field:
			dependent.field.input.focus();
			// refresh the current field to clear any errors and reset the value:
			this.refreshFields({
				names: [name],
				resetValue: true
			});

			// if the dependent field has no value...
			if (!dependent.hasValue()) {
				this.addMessageToField(dependent.name, "this field must be completed first", 1); // ... add a message to the dependent field to complete it first ...
			} else {
				this.addMessageToField(name, "address the issues with the previous field first", 1); // ... otherwise add a message to the current field to address the issues with the dependent field first.
			}
		}
		// otherwise, do nothing (i.e. the dependent field is complete and valid)
	}

	/**
	 * @method onInput - runs when the field is being typed into
	 * @param {string} name - name of the field
	 * Optional. If not provided, the 'field' parameter will need to be.
	 * @param {*} field - the field object (i.e. this.fields[name]).
	 * Optional. If not provided, the 'name' parameter will need to be: the field will be retrieved from the fields object using the 'name' argument.
	 */
	onInput(name, field = this.fields[name]) {
		// if this field is the dependent of another field ...
		if (field.dependentOf) {
			// ... refresh the field that is dependent on this field, and reset the value:
			// E.g if this is the password field, the confirm password field will be refreshed and reset (it shouldn't have any value until the password field is complete)
			this.refreshFields({
				names: [field.dependentOf],
				resetValue: true
			});
		}
		// validate the field in real-time, with a severity of 1 - Warning, and recheck for subsequent errors:
		this.processErrorLogs({name, recheck: true, severity: 1});
	}

	/**
	 * @method onLeave - runs when the field is left (i.e. user moves away from the input)
	 * @param {string} name - name of the field
	 * Optional. If not provided, the 'field' parameter will need to be.
	 * @param {Object} field - the field object (i.e. this.fields[name]).
	 * Optional. If not provided, the 'name' parameter will need to be: the field will be retrieved from the fields object using the 'name' argument.
	 */
	onLeave(name, field = this.fields[name]) {
		// validate the field, with a severity of 2 - Error, and process the errors in real-time:
		this.validateFields({fields: [name], processErrors: true, severity: 1});
	}
}
