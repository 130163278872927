class TOC_Highlight {
	constructor({
		toc,
		// scroll_area = ".sticky-sidebar",
		scroll_area = "#toc_scroll-area",
		toc_links = ".table-of-contents-link a",
		toc_title = ".table-of-contents-title"
	} = {}) {
		this.toc = toc;
		this.setup({
			scroll_area,
			toc_links,
			toc_title
		});
		this.init();
	}

	setup(options) {
		this.toc_links = Array.from(document.querySelectorAll(options.toc_links));
		this.scroll_area = document.querySelector(options.scroll_area);
		this.toc_title = document.querySelector(options.toc_title);
		this.current_link;
		this.previous_link = null;
		this.last_known_scroll_position = 0;
		this.wait_for_next_frame = false;
		this.direction;
		this.active_link = null;
		this.offset_down = 50;
		this.offset_up = 0;
		this.throttle_delay = 35;
	}

	init() {
		this.map_toc_links();
		this.bind_events();
	}

	map_toc_links() {
		if (this.toc_links.length) {
			// create a map of the headings on the page
			// and cache the nodes
			// id : { key: DOM Node }
			this.toc_map = {};
			this.toc_links.forEach((link) => {
				const id = this.get_id(link);
				this.toc_map[id] = {
					heading: document.querySelector(id),
					link
				};
			});

			this.toc_map.keys_going_down = Object.keys(this.toc_map);
			this.toc_map.keys_going_up = [...this.toc_map.keys_going_down].reverse();
		} else {
			this.error("No TOC links found in the DOM");
		}
	}

	// declare a function used to throttle window events
	throttle(payload, delay = this.throttle_delay) {
		let wait = false;
		let to = null;
		// return function that will be executed by the event handler
		return (e) => {
			// dont wait - execute the payload
			if (!wait) {
				payload(e);
				// defer the execution of the payload
				wait = true;
				setTimeout(() => {
					wait = false;
				}, delay);
			} else {
				// wait - clear previous time and
				// setup a new one which will execute the payload
				// when the event has finished
				if (to) clearTimeout(to);
				to = setTimeout(() => {
					payload(e);
				}, delay);
			}
		};
	}

	get_window_scrollY() {
		return window.scrollY ? window.scrollY : window.pageYOffset;
	}

	inside_scroll_area() {
		const windowY = this.get_window_scrollY();
		return windowY > windowY + this.scroll_area.getBoundingClientRect().top;
	}

	get_scroll_direction() {
		const windowY = this.get_window_scrollY();
		if (windowY > this.last_known_scroll_position) {
			this.direction = "down";
		} else if (windowY < this.last_known_scroll_position) {
			this.direction = "up";
		}
		return this.direction;
	}

	update_active_link(link, cl = "active") {
		if (link !== this.active_link) {
			if (this.active_link) {
				this.active_link.classList.remove(cl);
			}
			link.classList.add(cl);
			this.toc.scrollTop = this.modify_toc_scrolltop(link);
			this.active_link = link;
		}
	}

	modify_toc_scrolltop(link) {
		const link_h = link.getBoundingClientRect().height;
		const toc_h = this.toc.getBoundingClientRect().height;
		const title_height = this.toc_title.getBoundingClientRect().height;
		let toc_scrolltop = this.toc.scrollTop;

		if (this.direction === "down") {
			const link_offset_bottom = link.offsetTop + link_h;
			if (link_offset_bottom - this.toc.scrollTop >= toc_h) {
				toc_scrolltop = link_offset_bottom - toc_h;
			}
		} else if (this.direction === "up") {
			if (this.toc.scrollTop - link.offsetTop + title_height >= 0) {
				toc_scrolltop = link.offsetTop - title_height;
			}
		}
		return toc_scrolltop;
	}

	reset_active_link(cl = "active") {
		if (this.active_link) {
			this.active_link.classList.remove(cl);
		}
		if (this.direction === "up") this.toc.scrollTop = 0;
		this.active_link = null;
	}

	// monitors scroll area, keeping track of heading Y positions
	monitor_scroll_area() {
		switch (this.get_scroll_direction()) {
			case "down":
				this.check_heading_offsets(this.toc_map.keys_going_down, this.offset_down);
				break;
			case "up":
				this.check_heading_offsets(this.toc_map.keys_going_up, this.offset_up);
				break;
			default:
				break;
		}
	}

	slice_keys(keys) {
		const id = this.get_id(this.active_link);
		return keys.slice(keys.indexOf(id), keys.length);
	}

	check_heading_offsets(keys, offset) {
		// remove keys we have scrolled past
		const slice_keys = this.active_link ? this.slice_keys(keys) : keys;
		// check the bounds of the headings
		slice_keys.forEach((id) => {
			const heading = this.toc_map[id].heading;
			if (heading && this.heading_in_bounds(heading, offset, this.direction)) {
				const link = this.toc_map[id].link;
				this.current_link = link;
			}
		});
	}

	heading_in_bounds(heading, offset, direction) {
		// get section offset from top of viewport
		const heading_offset = heading.getBoundingClientRect().top;
		const heading_height = heading.getBoundingClientRect().height;
		return direction === "down"
			? heading_offset <= offset
			: heading_offset + heading_height >= offset;
	}

	reset_toc_link_state() {
		this.reset_active_link();
		this.current_link = null;
		this.previous_link = null;
	}

	manage_toc_link_state() {
		if (this.current_link && this.current_link !== this.previous_link) {
			this.update_active_link(this.current_link);
			this.previous_link = this.current_link;
		}
	}

	manage_toc_state() {
		this.inside_scroll_area() ? this.monitor_scroll_area() : this.reset_toc_link_state();
		this.manage_toc_link_state();
		this.last_known_scroll_position = this.get_window_scrollY();
		this.wait_for_next_frame = false;
	}

	request_frame() {
		// ensure we do the heavy lifting when the browser is ready.
		if (!this.wait_for_next_frame) {
			window.requestAnimationFrame(this.manage_toc_state.bind(this));
			this.wait_for_next_frame = true;
		}
	}

	bind_events() {
		const self = this;
		// add event listeners and throttle event
		window.addEventListener("scroll", self.throttle(self.request_frame.bind(self)));
		window.addEventListener("resize", self.throttle(self.request_frame.bind(self)));
	}

	get_id(el) {
		const href = el.getAttribute("href");
		const id = href.replace(/.+?(?=#)/, "");
		return id;
	}

	error(msg) {
		throw new Error(msg);
	}
}

// TODO BP: replace with utils
function animate_scroll(target, duration = 1000) {
	if (target && target.length) {
		$("html, body")
			.stop()
			.animate(
				{
					scrollTop: target.offset().top
				},
				duration,
				"linear",
				() => {
					const windowY = window.scrollY ? window.scrollY : window.pageYOffset;
					if (duration && Math.floor(windowY) < Math.floor(target.offset().top)) {
						animate_scroll(target, Math.floor(duration / 2));
					}
				}
			);
	}
}

export function initSidebarTableOfContents() {
	let active_anchor_link = null;

	const toc = document.getElementById("js-table-of-contents");

	$('a[href*="#"]:not([href="#"])').click(function (e) {
		active_anchor_link = toc ? document.querySelector(".table-of-contents-link a.active") : null;
		if (this !== active_anchor_link) {
			if (
				location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") &&
				location.hostname == this.hostname
			) {
				let target = $(this.hash);
				target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
				active_anchor_link = target.length ? this : null;
				animate_scroll(target);
			}
		} else {
			e.preventDefault();
		}
	});

	if (toc) {
		new TOC_Highlight({toc});
	}
}
