import {is_in_array, add_or_remove_from_array} from "../sf-utils";

export function initAdwordsHistory() {
	// Initialize adwords object:
	const adwords = {
		filters: {
			btns: $("[data-adwordsfilter]"), // All filter buttons
			active: [], // Currently active filters
			mainResetBtn: $("#sf-adwords-history__sidebar-btn--main-reset") // Main reset button
		},
		content: {} // Object to store content for each year
	};

	// Initialize content:
	$("[data-adwordsyear]").each(function () {
		const container = $(this); // The container for the year
		const year = container.attr("data-adwordsyear"); // The year (identifier)
		const items = container.find("[data-adwordscontent]").map(function () {
			return $(this); // Wrap each item in a jQuery object
		}); // All items in the year container (jQuery objects)
		adwords.content[year] = {
			container, // set the container for the year
			items: {
				active: [...items], // all items are active on first page load
				inactive: [] // no items are inactive on first page load
			},
			nocontent: container.find("[data-adwordsnocontent]") // the no content message inside the year container
		};
	});

	// Filter function
	function filterContent() {
		for (let [year, content] of Object.entries(adwords.content)) {
			// Create new arrays for active and inactive items
			const newActiveItems = [],
				newInactiveItems = [];

			// Process all items (both currently active and inactive)
			[...content.items.active, ...content.items.inactive].forEach(($item) => {
				const tags = $item.attr("data-adwordscontent").split(" "); // get the tags for the item
				const isActive =
					adwords.filters.active.length === 0 ||
					tags.some((tag) => adwords.filters.active.includes(tag)); // check if the item is active based on the tags on the item and the active filters

				isActive ? newActiveItems.push($item) : newInactiveItems.push($item); // add the item to the new active or inactive array
				$item.toggle(isActive); // show or hide the item immediately
			});

			// Update the active and inactive arrays:
			content.items.active = newActiveItems;
			content.items.inactive = newInactiveItems;

			// Show/hide "no content" message based on whether there's content or not:
			content.nocontent.toggle(content.items.inactive.length && !content.items.active.length); // if there's no content, show the no content message
		}
	}

	// Event listener for filter buttons
	adwords.filters.btns.on("click", function (e) {
		e.preventDefault(); // Prevent the default action of the button
		const filter = $(this).data("adwordsfilter"); // Get the filter from the button

		// If the filter is "reset"...
		if (filter === "reset") {
			adwords.filters.active = []; // ...reset the active filters...
			adwords.filters.btns.removeClass("sf-adwords-history__sidebar-btn--active"); // ...and remove the active class from all buttons
		} else {
			const isNewFilter = !is_in_array(adwords.filters.active, filter); // ...otherwise, check if the filter is new...
			$(this).toggleClass("sf-adwords-history__sidebar-btn--active", isNewFilter); // ...and add the active class to the button if it is
			add_or_remove_from_array(adwords.filters.active, filter, isNewFilter); // ...and add the filter to the active filters array
		}

		filterContent(); // Filter the content on the page

		adwords.filters.mainResetBtn.toggleClass(
			"sf-adwords-history__sidebar-btn--active",
			adwords.filters.active.length === 0
		); // Toggle the main reset button's active class based on the number of active filters
	});
}
