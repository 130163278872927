import {APP_PATH} from "../constants";

function initShowAllServices() {
	$("#sf-homepage-services-nav__show-all").click(function () {
		$("#sf-homepage-services-nav").addClass("sf-homepage-services-nav--show-all");
	});
}

function initHeroGraph() {
	//dot interaction on hero chart svg
	$(".sf-homepage-hero__graph svg .sf-homepage-hero__graph-dots--white g").mouseenter(function () {
		$(this).find(".st5").attr("rx", "11.2").attr("ry", "11.2");
	});
	$(".sf-homepage-hero__graph svg .sf-homepage-hero__graph-dots--white g").mouseleave(function () {
		$(this).find(".st5").attr("rx", "0").attr("ry", "0");
	});

	//reveal hero chart
	$("#sf-homepage-hero__graph").addClass("sf-homepage-hero__graph--revealed");
}

export function initHome() {
	initHeroGraph();
	initShowAllServices();
}
