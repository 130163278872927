function moveTableOfContents(tableOfContents) {
	const container = document.getElementById("sf-post-content");
	let position = tableOfContents.getAttribute("data-tableofcontentsposition");
	position = !position || position === "" || position === "false" ? 0 : parseInt(position);

	if (!container) {
		console.warn("MoveTableOfContents: elements missing");
		return false;
	}

	if (position > 0) {
		position = position - 1;
		var paragraph = container.getElementsByTagName("p")[position];
		paragraph ? paragraph.parentNode.insertBefore(tableOfContents, paragraph.nextSibling) : false;
	}
}

// for new TOC In posts + learn SEO
export function initTableOfContents() {
	$("[data-tableofcontentsposition]").each(function () {
		moveTableOfContents(this);
	});
}
