import {initCart} from "./initCart";
import {initCheckoutBillingDetails} from "./initCheckoutBillingDetails";
import {initSubscriptions} from "./initSubscriptions";
import {matchLocationPath} from "../sf-utils";

export function initCheckout() {
	initCart();

	if (matchLocationPath("/checkout/")) {
		initCheckoutBillingDetails();
	}
	// initSubscriptions(); <-- ready to use, but not used anywhere yet
}
