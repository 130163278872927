// TODO BP: investigate whether a plugin does this and remove file if not
function highdpi() {
	if ($("img.x2").css("font-size") == "1px") {
		const els = $("img.x2").get();
		for (let i = 0; i < els.length; i++) {
			let {src} = els[i];
			src = src.replace(".png", "@2x.png");
			src = src.replace(".jpg", "@2x.jpg");
			els[i].src = src;
		}
	}
}

export function initHighDPI() {
	highdpi();
}
