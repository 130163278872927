import {getParamsFromHREF, updateCurrentURLParams, addParamsToHREF} from "../sf-utils";

//TODO BP: Convert to function?
class CartQuantityControl {
	constructor({
		inputSelector,
		buttonDecreaseSelector,
		buttonIncreaseSelector,
		submitBtnSelector,
		priceTotalSelector,
		pricePerUserSelector,
		currencyControlSelector
	} = {}) {
		this.input = $(inputSelector);
		this.button = {
			decrease: $(buttonDecreaseSelector),
			increase: $(buttonIncreaseSelector)
		};
		this.price = {
			total: $(priceTotalSelector),
			perUser: $(pricePerUserSelector)
		};
		this.currencyControlLinks = $(currencyControlSelector).find("a");
		this.submitBtn = $(submitBtnSelector);
		this.post_id = this.input.parents("form.cart").find('input[name="add-to-cart"]').val();
		this.initiated =
			this.input.length === 1 &&
			(this.button.decrease.length || this.button.increase.length) &&
			this.post_id;
		if (this.initiated) {
			const self = this,
				initialValue = getParamsFromHREF("quantity") || self.input.val();

			if (initialValue > 1) {
				self.adjustPrices(self.getOrSetQuantity(initialValue));
			}

			this.button.increase.on("click", function (e) {
				e.preventDefault();
				self.incrementQuantity(1);
			});
			this.button.decrease.on("click", function (e) {
				e.preventDefault();
				self.incrementQuantity(-1);
			});
			this.input.on("change", function (e) {
				e.preventDefault();
				self.adjustPrices(self.getOrSetQuantity());
			});
		}
	}

	incrementQuantity(increase) {
		const qty = Number(this.input.val()) + increase,
			self = this;

		this.getOrSetQuantity(qty);

		if (this.cb) {
			clearTimeout(this.cb);
		}
		this.cb = setTimeout(function () {
			self.adjustPrices(qty);
		}, 500);
	}

	getOrSetQuantity(qty = this.input.val()) {
		if (qty < 1) {
			this.input.val(1);
			qty = 1;
		} else if (qty > 999) {
			this.input.val(999);
			qty = 999;
		}
		qty >= 999
			? this.button.increase.attr("disabled", "")
			: this.button.increase.removeAttr("disabled");
		qty <= 1
			? this.button.decrease.attr("disabled", "")
			: this.button.decrease.removeAttr("disabled");

		updateCurrentURLParams({quantity: qty});
		for (let link of this.currencyControlLinks) {
			link.href = addParamsToHREF({quantity: qty}, link.href);
		}

		this.input.val(qty);
		return qty;
	}

	adjustPrices(qty) {
		const self = this;

		self.price.perUser.html("Calculating...").addClass("calculating");
		self.price.total.html("Calculating...").addClass("calculating");
		self.submitBtn.attr("disabled", "");

		$.ajax({
			type: "post",
			// eslint-disable-next-line
			url: woocommerce_params.ajax_url,
			data: {
				action: "sf_wc_get_updated_price_and_total",
				post_id: self.post_id,
				qty
			},
			success: function (response) {
				response = JSON.parse(response);
				self.price.total.html(response["total"]).removeClass("calculating");
				self.price.perUser.html(response["price"]).removeClass("calculating");
				self.submitBtn.removeAttr("disabled");
			}
		});
	}
}

export function initBuyAndRenew() {
	new CartQuantityControl({
		inputSelector: '#product-add-to-cart__quantity-control input[name="quantity"]',
		buttonDecreaseSelector: "#product-add-to-cart__quantity-control-btn--decrease",
		buttonIncreaseSelector: "#product-add-to-cart__quantity-control-btn--increase",
		submitBtnSelector: "#product-add-to-cart__submit-btn",
		priceTotalSelector: "#product-add-to-cart__price--total p",
		pricePerUserSelector: "#product-add-to-cart__price--per-user p",
		currencyControlSelector: "#product-add-to-cart__currency"
	});
}
