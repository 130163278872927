/**
 * @function getParamsFromHREF - Get params from href
 * @param {string || array} params - params to get from href
 * @param {string} href - url to get params from
 * @returns {string} - params from href
 */
export function getParamsFromHREF(params = "", href = window.location.href) {
	const url = new URL(href, window.location.origin);

	if (!params || Array.isArray(params)) {
		const paramObj = {};

		url.searchParams.forEach(function (val, key) {
			if (!params || params.indexOf(key) != -1) {
				paramObj[key] = val;
			}
		});

		return paramObj;
	} else {
		return url.searchParams.get(params);
	}
}
