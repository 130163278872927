/**
 * @class that creates Event Delegators that listen for events
 * @version 1.5
 */
export class EventDelegator {
	/**
	 * @param {Object} config should have a delegators prop that contains an array of delegator config objects REQUIRED.
	 *  can have a stopprop prop that is a string. This is used to identify the data-stopprop that prevents event bubbling OPTIONAL.
	 */
	constructor(config = {}) {
		this.init(config);
	}

	init(config) {
		this.delegators = [];
		this.dataset = {};
		this.stopProp = config.stopprop ? config.stopprop : "stopprop";
		this.validate_delegators(config.delegators);
		this.bind_delegators();
	}

	/**
	 * @method
	 * checks array of delegator config objects have been passed in an array.
	 * @param {Array} delegators array of delegator config objects.
	 */
	validate_delegators(delegators) {
		if (this.is_array(delegators)) {
			delegators.forEach((delegator) => {
				this.validate_delegator(delegator);
			});
		}
	}

	/**
	 * @method
	 * checks each delegator config object is and object.
	 * @param {Object} delegator A delegator object that contains a CSS selector, eventType and the events.
	 */
	validate_delegator(delegator) {
		if (this.is_object(delegator)) {
			this.delegators.push(
				this.setup_delegator({
					...delegator
				})
			);
		} else {
			this.throw(`${delegator} is not an object`);
		}
	}

	/**
	 * @method
	 * builds config object for each delegator.
	 * @param {Object} delegator a delegator object that contains a CSS selector, eventType and the events.
	 * @return {Object} config object for each delegator.
	 */
	setup_delegator(delegator) {
		delegator.element = this.get_element(delegator.selector);
		delegator.eventType = !delegator.eventType ? "click" : delegator.eventType;

		if (!delegator.events) {
			this.throw(`${delegator.selector} does not contain any events...`, "warn");
		}
		return delegator;
	}

	/**
	 * @method
	 * caches the delegator element.
	 * @param {String} selector CSS selector.
	 * @return {Node} returns node element or throws error.
	 */
	get_element(selector) {
		const element = document.querySelector(selector);
		return (
			element ||
			this.throw(`Need a valid selector to delegate events - can't find ${selector} in the DOM`)
		);
	}

	/**
	 * @param {string} delegator.element the root DOM element that listens for all events.
	 * @param {string} delegator.eventType the event type, click, mouseout etc. Defaults to click.
	 * @param {Object} delegator.events an object where each key value pair is the target and callback. The key matches a data- attribute applied to a HTML element inside the delegator.
	 */
	bind_delegators() {
		const self = this;

		self.delegators.forEach((delegator) => {
			const {element, eventType, events} = delegator;

			element.addEventListener(
				eventType,
				(e) => {
					const targets_keys = this.extract_from_object(e.target.dataset);
					self.update_dataset(e.target.dataset, true);
					self.dataset.target = e.target;

					// see if target has handler to execute
					const callback_executed = self.execute_handler(e, events, targets_keys, self.dataset);
					// if the target doesn't have a handler then we will simulate bubbling
					// and check the parent nodes for handlers until we reach a handler or the root
					if (!this.includes(targets_keys, this.stopProp) && !callback_executed) {
						// harvest parents data-attributes
						const parents = self.get_parent_event_keys(e.target, events);
						const all_keys = this.combine_event_keys(targets_keys, parents.keys);
						// update the dataset.target so it is the current parent
						self.dataset.target = parents.target;
						self.execute_handler(e, events, all_keys, self.dataset, parents.target);
					}
				},
				this.enable_capturing(eventType)
			);
		});
	}

	/**
	 * @param {Object} e the selector for the parent HTML element that listens for all events.
	 * @param {Object} events object containing event keys and the handlers.
	 * @param {Array} keys array containing the keys harvested from the targets dataset.
	 * @param {Object} dataset object containing current targets dataset keys, values and the event target.
	 */
	execute_handler(e, events, keys, dataset) {
		const event_keys = this.extract_from_object(events);
		// const dataset_keys = this.extract_from_object( dataset );
		let callback_executed;
		callback_executed = false;

		// check the event keys and see if they match the key(s) fromm the current target
		// if they do execute the handler
		event_keys.forEach((key) => {
			if (this.includes(keys, key)) {
				events[key](e, dataset);
				callback_executed = true;
			}
		});

		return callback_executed;
	}

	combine_event_keys(targets_keys, parents_event_keys) {
		let keys = targets_keys;
		if (parents_event_keys.length) {
			keys = [...keys, ...parents_event_keys];
		}
		return keys;
	}

	/**
	 * @method
	 * transverses parent node elements
	 * @param {node} target e.target.
	 * @param {Object} events object containing event keys and the handlers.
	 * @return {Object}
	 */
	get_parent_event_keys(target, events) {
		let parent;
		let parents_event_keys;
		let current_parent;
		parent = target.parentElement;
		parents_event_keys = false;

		while (parent && !parents_event_keys) {
			if (parent) {
				current_parent = parent;
			}
			// find the closest parent that has a dataset key that matches the class events keys
			// if it does we've reached the summit
			parents_event_keys = this.parents_event_keys(parent, events);
			parent = parent.parentElement;
		}

		return {
			keys: parents_event_keys,
			target: current_parent
		};
	}

	/**
	 * @method
	 * harvests all the data-attributes on the parents of the e.target until we reach an element that contains an event key that is in the delegator config object or an element that contains data-stopprop.
	 * @param {node} parent current parent node.
	 * @param {Object} events object containing event keys and the handlers.
	 * @return {Array || Boolean} returns the parents event keys or false if it doesn't have any.
	 */
	parents_event_keys(parent, events) {
		const dataset_keys = this.extract_from_object(parent.dataset);
		const event_keys = this.extract_from_object(events);
		const dataset = parent.dataset;
		const parents_keys = [];

		// it the current parent has a data-value === 'stopprop' then we will short circuit and
		// return an empty array
		if (this.includes(dataset_keys, this.stopProp)) return [];

		if (dataset_keys) {
			// check if current parent as a data-attribute that matches the event keys in the delegator config object
			event_keys.forEach((event_key) => {
				if (this.includes(dataset_keys, event_key)) {
					parents_keys.push(event_key);
				}
			});
			// harvest all data-attributes on the way up
			this.update_dataset(dataset);
		}

		return parents_keys.length ? parents_keys : false;
	}

	/**
	 * @method
	 * updates the dataset which will be passed to the handler when executed.
	 * @param {Object} dataset object containing data-attribute key,value pairs.
	 * @param {Boolean} reset option to reset the dataset.
	 * @return {Object} updated dataset.
	 */
	update_dataset(dataset, reset) {
		const dataset_props = this.extract_from_object(dataset);

		if (dataset) {
			if (reset) {
				this.dataset = {};
			}
			dataset_props.forEach((prop) => {
				this.dataset[prop] = dataset[prop];
			});
		}
		return this.dataset;
	}

	/**
	 * @method
	 * enables event capturing so mouseenter and mouseleave events work.
	 * @param {String} eventType type of event.
	 * @return {Boolean}
	 */
	enable_capturing(eventType) {
		return this.includes(["mouseenter", "mouseleave"], eventType);
	}

	/**
	 * @method
	 * checks for an array.
	 * @param {Array?} delegators
	 * @return {Boolean} true or throws error.
	 */
	is_array(delegators) {
		return (
			Array.isArray(delegators) || this.throw("Delegators need  be passed as an array of objects")
		);
	}

	/**
	 * @method
	 * checks for an object.
	 * @param {Object?} delegator
	 * @return {Boolean}
	 */
	is_object(delegator) {
		return delegator && typeof delegator === "object" && delegator.constructor === Object;
	}

	/**
	 * @method
	 * extracts keys, values, entries.
	 * @param {Object} object object to extract.
	 * @param {string} extract keys, values, entries to extract - defaults to keys.
	 * @return {Array} array of object keys, values or entries.
	 */
	extract_from_object(object, extract = "keys") {
		return Object[extract](object);
	}

	/**
	 * @method
	 * checks if value is in an array.
	 * @param {Array} arr array to check.
	 * @param {string} val value to check against.
	 * @return {Boolean}
	 */
	includes(arr, val) {
		return arr.indexOf(val) !== -1;
	}

	/**
	 * @method
	 * throws error or console.warn.
	 * @param {Array} arr array to check.
	 * @param {string} val value to check against.
	 * @return {Boolean}
	 */
	throw(message, type = "error") {
		if (type === "error") {
			throw new Error(message);
		} else if (type === "warn") {
			console.warn(message);
		}
	}
}
