// refresh page if cart is empty
export function initCart() {
	$(document).on("click", ".product-remove .remove", function () {
		if ($(".product-remove .remove").length == 1) {
			setTimeout(() => {
				location.reload(true);
			}, 3000);
		}
	});
}
