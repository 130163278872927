import {exists} from "./exists";

/**
 * @function throw_error throw error
 * @param {*} warnAndReturn - turn error into warning and return this prop
 * @param {string} message - error message
 * @returns {string} - error message
 */
export function throw_error(message, warnAndReturn) {
	if (exists(warnAndReturn)) {
		console.warn(message);
		return warnAndReturn;
	} else {
		throw new Error(message);
	}
}
