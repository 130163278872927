/* TODO BP: replace with eb's Our Story code */
import "waypoints/lib/noframework.waypoints";
import Snap from "snapsvg/dist/snap.svg.js";

function timelineTextShow(timelineText, timelinePoint, reset = false) {
	if (reset) {
		timelinePoint.removeClass("animated fade-in draw-line");
		timelineText.removeClass("animated fade-in-right-small fade-in-left-small");
	} else {
		timelinePoint.addClass("animated fade-in");
		setTimeout(function () {
			timelinePoint.addClass("draw-line");
		}, 500);

		if (timelineText.hasClass("timeline-text--right")) {
			timelineText.addClass("animated fade-in-right-small");
		} else {
			timelineText.addClass("animated fade-in-left-small");
		}
	}
}

function timelineFlagAnimate(reset) {
	const flagSVG = $(".svg-flag");
	const pole = Snap("#flag");
	const flag = pole
		.path("M50,190 C50,190 50,190 50,190")
		.attr({fill: "none", stroke: "#000", strokeWidth: 2});

	if (reset) {
		flagSVG.removeClass("show-flag wave-flag");
	} else {
		flag
			.animate({d: "M50,190 C30,0 30,0 30,0"}, 100)
			.attr({fill: "none", stroke: "#000", strokeWidth: 2});
		setTimeout(function () {
			flagSVG.addClass("show-flag");
		}, 200);
		setTimeout(function () {
			flagSVG.addClass("wave-flag");
		}, 500);
	}
}

export function initStory() {
	// Fish swimming
	for (let i = 0; i <= 7; i++) {
		$(".timeline-sea--fish").append(
			'<div class="timeline-sea--fish_single timeline-sea--fish_single-' + i + '"></div>'
		);
	}

	// Tadpoles
	for (let i = 0; i <= 11; i++) {
		$(".timeline-sea--tadpoles-inner").append(
			'<div class="timeline-sea--tadpoles_single timeline-sea--tadpoles_single-' + i + '"></div>'
		);
	}

	$(".timeline-wrap").each(function () {
		var timelineWrap = $(this);
		var timelineText = timelineWrap.find(".timeline-text--show");
		var timelinePoint = timelineWrap.find(".timeline-text--point");

		//text fade in - downwards direction:
		const timelineTextDown = new Waypoint({
			element: timelineText[0],
			handler: function (direction) {
				if (direction == "down") {
					timelineTextShow(timelineText, timelinePoint);
				}
			},
			offset: "55%"
		});

		//text fade in - upwards direction:
		const timelineTextUp = new Waypoint({
			element: timelineText[0],
			handler: function (direction) {
				if (direction == "up") {
					timelineTextShow(timelineText, timelinePoint);
				}
			},
			offset: "0%",
			enabled: false
		});

		// reset looped elements and set waypoints on .[data-storystart] click:
		$("[data-storystart]").click(function (e) {
			e.preventDefault();
			//reset looped elements
			timelineTextShow(timelineText, timelinePoint, true);
			//enable/disable looped Waypoints
			timelineTextDown.disable();
			timelineTextUp.enable();
		});
	});

	// Rocket
	const timelineRocket = $(".timeline-sky--rocket");
	// Rocket (down)
	const timelineRocketDown = new Waypoint({
		element: timelineRocket[0],
		handler: function (direction) {
			if (direction == "down") {
				timelineRocket.addClass("fire");
			}
		},
		offset: "100%"
	});

	// Rocket (up)
	const timelineRocketUp = new Waypoint({
		element: timelineRocket[0],
		handler: function (direction) {
			if (direction == "up") {
				timelineRocket.addClass("fire");
			}
		},
		offset: "20%"
	});

	// Space Frog
	const timelineSpaceFrog = $(".timeline-sky--spacefrog");
	// Space Frog (down)
	const timelineSpaceFrogDown = new Waypoint({
		element: document.querySelector(".timeline-text--thirteen"),
		handler: function (direction) {
			if (direction == "down") {
				timelineSpaceFrog.addClass("animate slideUp");
			}
		},
		offset: "65%"
	});

	// Space Frog (up)
	const timelineSpaceFrogUp = new Waypoint({
		element: timelineSpaceFrog[0],
		handler: function (direction) {
			if (direction == "up") {
				timelineSpaceFrog.addClass("slideUp");
				setTimeout(function () {
					timelineSpaceFrog.addClass("animate");
				}, 2000);
			}
		},
		offset: "65%"
	});

	// Flag
	const flagBranch = $(".timeline-sky--branch img");
	// Animate Flag Pole
	const animateFlagDown = new Waypoint({
		element: flagBranch[0],
		handler: function (direction) {
			if (direction == "down") {
				timelineFlagAnimate();
			}
		},
		offset: "65%"
	});

	const animateFlagUp = new Waypoint({
		element: flagBranch[0],
		handler: function (direction) {
			if (direction == "up") {
				timelineFlagAnimate();
			}
		},
		offset: "25%"
	});

	// Increase timeline line with scroll - Horizontal / Vertical
	var lastVal = 0;
	$(window)
		.scroll(function () {
			var $line = $(".timeline-text--line");
			var st = $(this).scrollTop() - 440;
			$line.height(st);
			lastVal = st;
		})
		.scroll();

	$(window)
		.scroll(function () {
			var $line = $(".timeline-text--line_down");
			var st = $(this).scrollTop() - 440;
			var downHeight = 5235 - st;
			$line.height(downHeight);
		})
		.scroll();

	// Scroll to bottom of story page
	$("[data-storystart]").click(function (e) {
		e.preventDefault();

		//enable/diable waypoints
		timelineRocketDown.disable();
		timelineRocketUp.enable();
		timelineSpaceFrogDown.disable();
		timelineSpaceFrogUp.enable();
		animateFlagDown.disable();
		animateFlagUp.enable();

		//reset animations/classes
		timelineFlagAnimate(true);
		timelineRocket.removeClass("fire");
		timelineSpaceFrog.removeClass("animate slideUp");

		//set timeline line direction
		$(".timeline-text--line").removeClass("timeline-text--line").addClass("timeline-line");
		setTimeout(function () {
			$(".timeline-line").addClass("timeline-text--line_down");
		}, 500);

		//animate to the bottom of the page
		$("body,html").animate(
			{
				scrollTop: $(".timeline-text--one").offset().top
			},
			500
		);
	});
}
