import {string_to_array} from "../string_to_array";
import {throw_error} from "../throw_error";

/**
 * @class that caches DOM elements.
 * @version 1.0
 */
export class CacheElement {
	constructor() {
		this.elements = {
			single: {},
			multi: {}
		};
		return this;
	}

	/**
	 * @method get_node cache single Nodes
	 * @param {String} selector - CSS selector used to cache element
	 * @return {Node}
	 */
	get_node(selector) {
		const self = this;
		return (self.elements.single[selector] = self.elements.single[selector]
			? self.elements.single[selector]
			: document.querySelector(selector));
	}

	/**
	 * @method get_nodelist cache multiple Nodes
	 * @param {String} selector - CSS selector used to cache all elements
	 * @return {NodeList}
	 */
	get_nodelist(selector) {
		const self = this;
		return (self.elements.multi[selector] =
			self.elements.multi[selector] && self.elements.multi[selector].length > 0
				? self.elements.multi[selector]
				: document.querySelectorAll(selector));
	}

	/**
	 * @method get_elements returns this.elements
	 * @return {Object}
	 */
	get_elements() {
		return this.elements;
	}

	/**
	 * @method get_all_selectors returns all current selectors
	 * @param {string} type - get selectors just from 'multi' or 'single' elements. OPTIONAL - defaults to getting selectors from both.
	 */
	get_all_selectors(type = "all") {
		return type === "all"
			? [...Object.keys(this.elements.multi), ...Object.keys(this.elements.single)]
			: this.elements[type]
			? Object.keys(this.elements[type])
			: throw_error(`CacheElement.get_all_selectors : invalid type ${type}`);
	}

	/**
	 * @method refresh - rescan the DOM to update elements in cache
	 * @param {string || array} selector - target specific selector(s) to refresh - pass multiple selectors as a string separating each with a space, or as an array.
	 * OPTIONAL: defaults to refreshing all selectors.
	 * @param {string || array} type - refresh elements just in 'multi' or 'single' elements.
	 * OPTIONAL - defaults to refreshing elements in both.
	 */
	refresh(selector = "all", type = "all") {
		selector = selector === "all" ? this.get_all_selectors(type) : string_to_array(selector);
		type = type === "all" ? ["multi", "single"] : string_to_array(type);

		for (const ty of type) {
			if (ty === "multi" || ty === "single") {
				for (const select of selector) {
					if (this.elements[ty][select]) {
						this.elements[ty][select] =
							ty === "multi" ? document.querySelectorAll(select) : document.querySelector(select);
					} else if (
						type.length === 1 ||
						(!this.elements.single[select] && !this.elements.multi[select])
					) {
						throw_error(`CacheElement.refresh : selector "${select}" not found`);
					}
				}
			} else {
				throw_error(`CacheElement.refresh : invalid type ${type}`);
			}
		}
	}
}
