import {FormValidator} from "./FormValidator";
import {CheckoutVerifier} from "./CheckoutVerifier";

// Defines a class to handle billing details during checkout.
export class CheckoutBillingDetails {
	/**
	 * @constructor - Initialize the billing details form with the provided fields.
	 * @param {Object} fields - Object containing the fields to be validated and verified, with their respective validation rules held in an array for each field (prop).
	 */
	constructor(fields) {
		// Initialize validator with specific settings and bind it to the form.
		this.validator = new FormValidator("checkout", fields, false, false);
		this.form = this.validator.form; // Reference to the form object from the validator.

		// If the form exists, proceed to set up verifier, fields, and UI elements.
		if (this.form) {
			this.verifier = new CheckoutVerifier(this.validator); // Setup verifier with the validator.
			this.fields = this.validator.fields; // Shortcut reference to form fields.

			// Cache jQuery selectors for form buttons for easy access.
			this.btn = {
				save: $('form[name="checkout"] #checkout-save-billing-btn'),
				edit: $('form[name="checkout"] #checkout-edit-billing-btn'),
				placeOrder: $('form[name="checkout"] #place_order')
			};

			// Cache jQuery selectors for various parts of the form container.
			this.container = {
				form: $('form[name="checkout"] #checkout-billing-form'),
				submit: $('form[name="checkout"] #checkout-billing-form__submit'),
				review: $('form[name="checkout"] #checkout-billing-review')
			};

			// Initialize form with tax checkbox and open it for editing.
			this.addTaxCheckboxAndTooltip();
			this.openForm();

			// Setup event listeners for form buttons and other interactions.
			this.setupEventListeners();
		}
	}

	/**
	 * @method activateLoadingStateOnSaveBtn - activate (or deactivate) the loading state on the save button (i.e disabled with css loading indicator)
	 * @param {Boolean} active - Whether to activate the loading state on the save button or not. Default is true.
	 */
	activateLoadingStateOnSaveBtn(active = true) {
		this.disableBtn("save", active); // Disable or enable the save button.
		this.container.submit.addRmvClass("checkout-billing-form__submit--loading", active); // Toggle loading class.
	}

	/**
	 * @method disableBtn - Disable or enable a button by name.
	 * @param {string} btn - The name of the button to disable or enable.
	 * @param {*} disable - Whether to disable or enable the button. Default is true.
	 */
	disableBtn(btn, disable = true) {
		btn = this.btn[btn] ? this.btn[btn] : false; // Get the button from cache or return false.
		// if successfully retreieved from cache ...
		if (btn) {
			disable ? btn.attr("disabled", "") : btn.removeAttr("disabled"); // ... disable or enable the button via the 'disabled' attribute on the element.
		}
	}

	/**
	 * @method addTaxCheckboxAndTooltip - Add a checkbox for tax ID declaration and tooltip for additional info.
	 */
	addTaxCheckboxAndTooltip() {
		// get and set variables needed in this method:
		const taxIdField = this.fields.tax_id; // get the cached tax ID field
		let checkboxLabelText = "I do not have a tax ID"; // set default checkbox label text
		checkboxLabelText += !this.isUK()
			? ".  I acknowledge tax could be added to the order if required by my tax authority."
			: "."; // add additional text for non-UK customers

		// remove any existing tax ID info boxes and tax acknowledgement checkboxes:
		taxIdField.container
			.find(".sf-hint-help, .checkout-billing-form__tax-acknowledgement")
			.remove();
		// add a new tax ID info box and tax acknowledgement checkbox:
		taxIdField.label.append(`
    <span class="sf-hint-help sf-hint-help--offset-top sf-hint-help--maintain-width">
      <i class="ri-question-fill" data-tippy-content="Business customers outside of the UK will not have tax collected if your tax ID is validated by your tax authority"></i>
    </span>
    `);
		taxIdField.container.append(`
    <div class="checkout-billing-form__tax-acknowledgement">
      <input type="checkbox" name="tax_id_checkbox" id="tax_id_checkbox" />
      <label>${checkboxLabelText}</label>
    </div>
    `);
		// cache the checkbox for easy access:
		taxIdField.checkbox = taxIdField.container.find("#tax_id_checkbox");
		// add event listeners to the tax ID input field and checkbox:
		taxIdField.checkbox.change(() => {
			this.validator.refreshFields({names: ["tax_id"], resetValue: true});
		});
		taxIdField.input.on("input", () => {
			if (taxIdField.input.val() !== "") {
				taxIdField.checkbox.prop("checked", false);
			}
		});
	}

	/**
	 * @method processWooCommerceErrors - Process WooCommerce errors and display them next to the relevant field.
	 */
	processWooCommerceErrors() {
		const self = this; // Cache reference to this for use in each loop.
		// Loop through each error and display it next to the relevant field:
		$(".woocommerce-error")
			.find("li")
			.each(function (i, err) {
				const target = err.getAttribute("data-id"); // Get the target field name from the error.
				// If the target & target field exists, open the form and add the error message to the field.
				if (target && self.fields[target]) {
					!self.form.open ? self.openForm(true) : false; // if form is closed, force it open
					self.validator.addMessageToField(
						target, // target field name
						err.innerHTML, // error message (taken from the WooCommerce error in list)
						2 // severity level (2 = error)
					);
				}
			});
	}

	// Toggle the form between open and closed states, and handle UI changes accordingly.
	/**
	 * @method openForm - Toggle the form between open and closed states, and handle UI changes accordingly.
	 * @param {Boolean} openForm - Whether to force the form to open or closed. Optional - will be determined by verifier if not provided.
	 */
	async openForm(openForm) {
		// set form state, to open or closed, based on the openForm parameter or the result of the verifier validation and verification:
		this.form.open = openForm || !(await this.verifier.validateAndVerify(this.form.open, this));
		// apply open/closed state to the form element via a data-attribute, to apply css styles:
		this.form.element.attr("data-formopen", this.form.open);

		// if the form is open...
		if (this.form.open) {
			this.disableBtn("placeOrder", true); // ... disable the place order button while form is open
		}
		// if the form is closed...
		else {
			// ... if the billing country is UK ...
			if (this.isUK()) {
				// ... only print the tax ID if it has a value (won't prompt users to add a tax ID) ...
				this.printBillingDetails(Boolean(this.fields.tax_id.value));
			}
			// ... else if any other country ...
			else {
				// ... only print the tax ID if it is required (will prompt users to add a tax ID if they haven't added one) ...
				this.printBillingDetails(this.fields.tax_id.required);
			}

			// ... sync the tax ID fields (input and hidden field for WooCommerce) ...
			this.syncTaxIDs();
			// ... enable the place order button to finish the checkout.
			this.disableBtn("placeOrder", false);
		}
	}

	// Synchronize tax ID fields between the form and another component or storage.
	/**
	 * @method syncTaxIDs - Synchronize the 2 tax ID fields in the form - one editable input for user, and one hidden input for WooCommerce.
	 */
	syncTaxIDs() {
		$("#billing_tax_id").val(this.fields.tax_id.value); // N.B cannot cache #billing_tax_id input as it can be added and removed from the form by WooCommerce.
	}

	/**
	 * @method isUK - Check if the billing country is UK to apply specific logic.
	 * @returns {Boolean} - Whether the billing country is UK or not.
	 */
	isUK() {
		return this.fields.billing_country.value === "GB";
	}

	/**
	 * @method printBillingDetails - Print the Billing Details to the "review" section of the checkout page.
	 * @param {Boolean} printTaxID - Whether to print the tax ID/tax prompt or not.
	 */
	printBillingDetails(printTaxID) {
		this.container.review.html(""); // Clear the review section before printing new details.
		// Create the markup for the Billing Details Review section with the values from the form fields.
		let markup = `
      <p>${this.getValue(["billing_first_name", "billing_last_name"])}</p>
      <p>${this.getValue("billing_email")}</p>
      <p>${this.getValue("customer_reference")}</p>
      <br>
      <p>${this.getValue("billing_company")}</p>
      <p>${this.getValue("billing_address_1")}</p>
      <p>${this.getValue("billing_address_2")}</p>
      <p>${this.getValue(["billing_city", "billing_state", "billing_postcode"], ",")}</p>
      <p>${this.getValue("billing_country")}</p>`;
		// if printTaxID is true, add the tax ID info to the markup (will print the value if it has been set, or prompt user to add it if not)
		if (printTaxID) {
			markup += `<br>
        <p>
        <b>Tax ID</b><span class="sf-hint-help sf-hint-help--offset-top sf-hint-help--maintain-width">
          <i class="ri-question-fill" data-tippy-content="Business customers outside of the UK will not have tax collected if your tax ID is validated by your tax authority"></i></span><b>:</b> ${
						this.fields.tax_id.value ||
						"No Tax ID <br><a class='checkout-billing-review__edit-tax-id' id='checkout-billing-review__edit-tax-id'>Add a Tax ID</a>"
					}</p>`;
		}
		// append the markup to the Billing Details Review section of the checkout page.
		this.container.review.append(markup);
	}

	/**
	 * @method getValue - Retrieve and format the value(s) of specified field(s) for display.
	 * @param {string || Array} name - The name of the input to retrieve the value from. Can be an array of names to retrieve multiple values.
	 * @param {string} suffix - The suffix to append to each value retrieved. Default is an empty string. Common use case is to use a comma.
	 * @returns {string} - The formatted value(s) of the specified field(s) for display.
	 */
	getValue(name, suffix = "") {
		// if the name is an array of names, loop through each name and get the value of each field, then join them together with the suffix:
		if (Array.isArray(name)) {
			let value = ""; // initate an empty string to add values too in the loop
			name.forEach((n, i) => {
				value += this.getValue(n, i + 1 !== name.length ? suffix + " " : ""); // add the value of the field to the string, and add the suffix (plus a space) if it's not the last field
			});
			return value; // return the joined string of values
		}
		// else handle the single name:
		else {
			const item = this.fields[name]; // get the field from the fields object
			const value = item.input.is("select")
				? item.input.find(`option[value="${item.value}"]`).text()
				: item.value; // if the field is a select, get the text of the selected option, else get the value of the input
			return value ? value + suffix : ""; // if successfully retrieved a value, return it (with the suffix if required), else return an empty string
		}
	}

	// Setup event listeners for various user interactions and form events.
	setupEventListeners() {
		// Save button click event to remove WooCommerce Errors and (toggle) the form to closed.
		this.btn.save.click(() => {
			$(".woocommerce-NoticeGroup-checkout, .woocommerce-error").remove(); // remove any WooCommerce errors
			this.openForm(); // toggle the form to closed (should be open by default to access this button)
		});

		// Edit button click event to (force) the form to open.
		this.btn.edit.click(() => {
			this.openForm(true); // force open the form
		});

		// WooCommerce event listeners to handle form changes and errors.
		const self = this; // Cache reference to this for use in WooCommerce event listeners.

		// Handle changes to the billing country and state fields.
		$(document.body).on("country_to_state_changed", function () {
			self.validator.refreshFields(); // Refresh the form fields.
			self.addTaxCheckboxAndTooltip(); // Add (or remove) the tax checkbox and tooltip if required/unrequired.
		});

		// Handle WooCommerce errors during checkout.
		$(document.body).on("checkout_error", function () {
			self.processWooCommerceErrors(); // Process WooCommerce errors and display them next to the relevant field.
		});

		// disable save button temporarily while WooCommerce updates checkout details
		$(document).on("update_checkout", function () {
			self.activateLoadingStateOnSaveBtn();
		});

		// enable save button after WooCommerce updates checkout details
		$(document).on("updated_checkout", function () {
			self.activateLoadingStateOnSaveBtn(false);
		});

		// Handle click event to edit the tax ID field (in the Billing Information Review) - Force open the form, scroll to the TAX ID input and give the user a message
		$(document.body).on(
			"click",
			"form[name='checkout'] #checkout-billing-review__edit-tax-id",
			function () {
				self.openForm(true); // force open the form
				self.validator.addMessageToField("tax_id", "Enter tax ID here", 0); // add a message to the tax ID field to alert user to add the Tax ID here.
				$("html, body").animate({
					scrollTop: self.validator.fields.tax_id.container.offset().top - 15,
					scrollLeft: 0
				}); // scroll to the tax ID field
			}
		);
	}
}
