import {Loader} from "google-maps";
/*
TODO BP:
This currently inits a map for the training page or the contact page. Probably best
to refactor this to be a reusable Map class or function

*/

export function initMaps() {
	const loader = new Loader("AIzaSyB9b1jndpB20PPuKXoHJYAvlr1EI9biQSc");
	loader.load().then((google) => {
		var trainingPage = $("section.location").length;
		// Screaming Frog Map
		if ($(".contact-wrap").length || trainingPage) {
			initMap();
		}

		var map;
		var marker;
		function initMap() {
			var myLatLng = trainingPage
				? {lat: 51.515505, lng: -0.163245}
				: {lat: 51.53619, lng: -0.904578};
			var styleArray = [
				{
					featureType: "all",
					stylers: [{saturation: -80}]
				}
			];

			var sfContentString =
				'<div class="map-tooltip">' +
				"<h2>Screaming Frog Ltd</h2>" +
				"<ul>" +
				"<li>6 Greys Road</li>" +
				"<li>Henley-on-Thames</li>" +
				"<li>Oxfordshire, RG9 1RY</li>" +
				"</ul>" +
				"</div>";

			var sfTrainingContentString =
				'<div class="map-tooltip">' +
				"<h2>Marble Arch - Edgware Rd</h2>" +
				"<ul>" +
				"<li>Garfield House</li>" +
				"<li>86 Edgware Road</li>" +
				"<li>London, W2 2EA</li>" +
				"</ul>" +
				"</div>";

			var contentString = trainingPage ? sfTrainingContentString : sfContentString;

			var infowindow = new google.maps.InfoWindow({
				content: contentString
			});

			var mapID = trainingPage ? "js-training-map" : "map";

			map = new google.maps.Map(document.getElementById(mapID), {
				center: myLatLng,
				zoom: 14,
				disableDefaultUI: true,
				styles: styleArray
			});

			var myIcon = new google.maps.MarkerImage(
				"https://www.screamingfrog.co.uk/wp-content/themes/screamingfrog/public/images/map-pin@2x.png",
				null,
				null,
				null,
				new google.maps.Size(38, 48)
			);

			marker = new google.maps.Marker({
				map: map,
				draggable: false,
				animation: google.maps.Animation.DROP,
				position: myLatLng,
				icon: myIcon,
				size: (38, 48)
			});

			marker.addListener("click", function () {
				infowindow.open(map, marker);
			});
		}
	});
}
