import {CacheElement} from "./classes/CacheElement";
import {prepend_to_string} from "./prepend_to_string";
/**
 * @function set value of attribute
 * @param {string} attribute - attribute name
 * @param {string} value - value to assign
 * @param {string} specificTarget - query selector to only change a specific target
 */
const set_attr_cache = new CacheElement();
export function set_attr(attribute, value, specificTarget) {
	attribute = prepend_to_string(attribute);
	const targets = set_attr_cache.get_nodelist(
		specificTarget ? `${specificTarget}[${attribute}]` : `[${attribute}]`
	);
	for (const t of targets) {
		t.setAttribute(attribute, value);
	}
}
