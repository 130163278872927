import {addParamsToHREF} from "./addParamsToHREF";

/**
 * @function updateCurrentURLParams - Updates the current URL with the given params
 * @param {string} params - params to update
 */
export function updateCurrentURLParams(params) {
	if (window.history.replaceState) {
		window.history.replaceState({}, "", addParamsToHREF(params));
	}
}
