import {Accordion, matchLocationPath as MatchLocationPath} from "../sf-utils";

export function initFAQ() {
	if (MatchLocationPath(["seo-spider/faq", "log-file-analyser/faq/"], false)) {
		const faq_accordion = new Accordion({
			selector: "[data-accordionfaq]",
			single_panel: false,
			callback: (panel) => {
				const faq_id = panel.head.getAttribute("data-faqid");
				if (faq_id) {
					location.hash = faq_id;
				}
			}
		});

		const scroll_to = (element, container = window) => {
			// get the Y position of the element
			const offset =
				(container.scrollY ? container.scrollY : container.pageYOffset) +
				element.getBoundingClientRect().top;
			container.scroll(0, offset);
		};

		const accordion_handler = (faq_panel) => {
			// open the faq accordion / hand off scroll_to to execute when the final transition has finished
			faq_accordion.transition_panel(faq_panel.getAttribute("data-accordion-id"), [
				() => {
					// get the Y position of the faq and scoll to it
					scroll_to(faq_panel);
				}
			]);
		};

		$(document).ready(function () {
			// grab the hash
			const url_hash = location.hash ? location.hash.replace("#", "") : null;

			if (url_hash) {
				// find the accordion panel withthe requested faq id
				const faq_panel = document.querySelector(`[data-faqid="${url_hash}"]`);

				if (faq_panel) {
					// get the faq category
					const faq_cat = faq_panel.getAttribute("data-faqcat");
					// open the faq category accordion and then open the faq accordion
					faq_accordion.transition_panel(`#faq-panel-${faq_cat}`);
					accordion_handler(faq_panel);
				}
			}
		});

		$('.accordion__panel--content-body a[href^="#"]:not([href="#"])').click(function (e) {
			e.preventDefault();
			location.hash = $(this).attr("href");
			location.reload();
		});
	}
}
