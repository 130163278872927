import {tns as tinySlider} from "tiny-slider";
import {insert_stylesheet} from "../sf-utils";

export async function initCarousel() {
	await insert_stylesheet(
		`https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.4/tiny-slider.css`,
		"for tinyslider on homepage"
	);

	$("#sf-carousel").show();

	tinySlider({
		container: "#sf-carousel__items",
		items: 1,
		gutter: 20,
		mouseDrag: true,
		swipeAngle: false,
		speed: 400,
		loop: false,
		prevButton: "#sf-carousel__control-btn--prev",
		nextButton: "#sf-carousel__control-btn--next",
		nav: false,
		autoHeight: true,
		responsive: {
			700: {
				items: 1.5
			},
			1000: {
				items: 2,
				gutter: 40
			},
			1350: {
				gutter: 60
			}
		}
	});
}
