import tippy from "tippy.js";

function makeCopyable(target) {
	const tippyCtx = tippy(target, {
		content: "Click to Copy",
		theme: "sf-grey",
		arrow: false,
		placement: "right"
	});

	let debounceTimeout;

	target.addEventListener("click", () => {
		// prevent confusing tippy by debouncing the ability to copy
		clearTimeout(debounceTimeout);

		// get element's innerText and save to clipboard
		var text = target.innerText;
		navigator.clipboard.writeText(text);

		// update tippy's content
		tippyCtx.setContent("Copied!");
		tippyCtx.show();

		// begin timeout to reset tippy's content
		debounceTimeout = setTimeout(() => {
			tippyCtx.setContent("Click to Copy");
		}, 2000);
	});
}

export function initCopyableContent(selector) {
	const targets = document.querySelectorAll(selector);
	targets.forEach((t) => {
		makeCopyable(t);
	});
}
