import {CacheElement} from "./classes/CacheElement";

/**
 * easing animations
 */
const easings = {
	linear: function (t, b, c, d) {
		return c * (t / d) + b;
	},
	quadIn: function (t, b, c, d) {
		t /= d;
		return c * t * t + b;
	},
	quadOut: function (t, b, c, d) {
		t /= d;
		return -c * t * (t - 2) + b;
	},
	quadInOut: function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) {
			return (c / 2) * t * t + b;
		}
		t--;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	},
	cubicIn: function (t, b, c, d) {
		t /= d;
		return c * t * t * t + b;
	},
	cubicOut: function (t, b, c, d) {
		t /= d;
		t--;
		return c * (t * t * t + 1) + b;
	},
	cubicInOut: function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) {
			return (c / 2) * t * t * t + b;
		}
		t -= 2;
		return (c / 2) * (t * t * t + 2) + b;
	},
	quarticIn: function (t, b, c, d) {
		t /= d;
		return c * t * t * t * t + b;
	},
	quarticOut: function (t, b, c, d) {
		t /= d;
		t--;
		return -c * (t * t * t * t - 1) + b;
	},
	quarticInOut: function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) {
			return (c / 2) * t * t * t * t + b;
		}
		t -= 2;
		return (-c / 2) * (t * t * t * t - 2) + b;
	},
	quinticIn: function (t, b, c, d) {
		t /= d;
		return c * t * t * t * t * t + b;
	},
	quinticOut: function (t, b, c, d) {
		t /= d;
		t--;
		return c * (t * t * t * t * t + 1) + b;
	},
	quinticInOut: function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) {
			return (c / 2) * t * t * t * t * t + b;
		}
		t -= 2;
		return (c / 2) * (t * t * t * t * t + 2) + b;
	},
	sinusoidalIn: function (t, b, c, d) {
		return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
	},
	sinusoidalOut: function (t, b, c, d) {
		return c * Math.sin((t / d) * (Math.PI / 2)) + b;
	},
	sinusoidalInOut: function (t, b, c, d) {
		return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
	},

	exponentialIn: function (t, b, c, d) {
		return c * Math.pow(2, 10 * (t / d - 1)) + b;
	},
	exponentialOut: function (t, b, c, d) {
		return c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
	},
	exponentialInOut: function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) {
			return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
		}
		t--;
		return (c / 2) * (-Math.pow(2, -10 * t) + 2) + b;
	},

	circularIn: function (t, b, c, d) {
		t /= d;
		return -c * (Math.sqrt(1 - t * t) - 1) + b;
	},
	circularOut: function (t, b, c, d) {
		t /= d;
		t--;
		return c * Math.sqrt(1 - t * t) + b;
	},
	circularInOut: function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) {
			return (-c / 2) * (Math.sqrt(1 - t * t) - 1) + b;
		}
		t -= 2;
		return (c / 2) * (Math.sqrt(1 - t * t) + 1) + b;
	}
};

const scrollCache = new CacheElement();

/**
 * Smooth Scroll - scroll to the top of an element in a smooth manner (cross browser compatible)
 * @param {string} scrollContainer - container the scroll is to be applied to:
 * Query Selector (e.g '#target, '.target', '[attribute]' etc) of scroll container.
 * OPTIONAL - defaults to body.
 *
 * @param {string} target - Query Selector (e.g '#target, '.target', '[attribute]' etc) of target to scroll to.
 * OPTIONAL - will only scroll offset distance from current scroll position if not included.
 *
 * @param {number} offsetDistance - distance to scroll in addition to distance to target.
 * If no target, this is the total scroll distance.
 * OPTIONAL - defaults to 0.
 *
 * @param {number} duration - duration of scroll animation.
 * OPTIONAL - defaults to 1000ms.
 *
 * @param {boolean} horizontalScroll - scroll along the x axis.
 * OPTIONAL - defaults to false.
 *
 * @param {string} easeType - name of easetype animation, relates to a prop in the easings object.
 * OPTIONAL - defaults to 'exponentialInOut'.
 *
 * @param {boolean} backToTop - scroll to top of scrollContainer. Overrides any targets, but offsetDistance will still apply.
 * E.g if offsetDistance is 500, it will scroll to 500px from the top.
 * OPTIONAL - defaults to false.
 */
export function smooth_scroll({
	scrollContainer,
	target,
	offsetDistance = 0,
	horizontalScroll = false,
	duration = 1000,
	easeType = "exponentialInOut",
	backToTop = false //overrides target
}) {
	let position = {};
	scrollContainer = !scrollContainer
		? false
		: scrollContainer === "body"
		? false
		: document.querySelector(scrollContainer);

	if (horizontalScroll) {
		position.start = scrollContainer ? scrollContainer.scrollLeft : window.pageXOffset;
	} else {
		position.start = scrollContainer ? scrollContainer.scrollTop : window.pageYOffset;
	}

	// If target set and not overridden by BackToTop, look for the node, which may return null if it doesn't exist.
	target = backToTop || !target ? false : scrollCache.get_node(target);
	if (target) {
		if (horizontalScroll) {
			position.target = scrollContainer
				? target.offsetLeft - scrollContainer.getBoundingClientRect().left
				: target.getBoundingClientRect().left;
		} else {
			position.target = scrollContainer
				? target.offsetTop - scrollContainer.getBoundingClientRect().top
				: target.getBoundingClientRect().top;
		}
		offsetDistance = backToTop
			? offsetDistance
			: scrollContainer
			? position.target - position.start + offsetDistance
			: position.target + offsetDistance;
	}

	offsetDistance = backToTop ? position.start * -1 + offsetDistance : offsetDistance;
	duration = duration <= 0 ? 1 : duration;
	let startTime;

	function scrollAnimation(currentTime) {
		startTime = startTime ? startTime : currentTime;
		const timeElapsed = currentTime - startTime;
		const scroll = easings[easeType](timeElapsed, position.start, offsetDistance, duration);

		if (horizontalScroll) {
			scrollContainer ? (scrollContainer.scrollLeft = scroll) : window.scrollTo(scroll, 0);
		} else {
			scrollContainer ? (scrollContainer.scrollTop = scroll) : window.scrollTo(0, scroll);
		}

		if (timeElapsed < duration) {
			requestAnimationFrame(scrollAnimation);
		}
	}

	requestAnimationFrame(scrollAnimation);
}
