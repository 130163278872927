import {initAssignedUserChecker} from "./initAssignedUserChecker";
import {initEditAccount} from "./initEditAccount";
import {matchLocationPath} from "../sf-utils";
import {initResetPassword} from "./initResetPassword";

export function initAccount() {
	if (matchLocationPath(["/my-account"], false)) {
		initAssignedUserChecker();
		initEditAccount();
		initResetPassword();

		// EDIT ADDRESS VALIDATION:
		// SF TOOD: Add validation for edit address form
		// new FormValidator(
		// 	"register",
		// 	{
		// 		billing_first_name: ["hasValue"],
		// 		billing_last_name: ["hasValue"],
		// 		username: [
		// 			"hasValue",
		// 			"noEmail",
		// 			"noSpaces",
		// 			"isLowercaseAlphaNumeric",
		// 			"has4Letters",
		// 			"maxLength30"
		// 		],
		// 		email: ["hasValue", "isEmail"]
		// 	},
		// 	true
		// );
	}
}
