/**
 * @function addParamsToHREF - adds params to a url
 * @param {Object} params - params to add to url
 * @param {string} href - url to add params to
 * @returns {string} - url with params added
 */
export function addParamsToHREF(params = {}, href = window.location.href) {
	const url = new URL(href, window.location.origin);
	for (let [key, value] of Object.entries(params)) {
		url.searchParams.set(key, value);
	}
	return url.href;
}
