/**
 * @class that rearranges elements from one container to another container on page resize.
 * Work from a mobile first approach:
 * from 0 - [first defined breakpoint], the elements remain in the container defined in the markup.
 * @version 2.1
 */
export class ResponsiveRearrange {
	/**
	 * @constructor
	 *
	 * @param {array, object} setup - REQUIRED. an obj or array of objs that takes the following props:
	 *
	 * @property {boolean} respondOnCompletion - remove style attribute (with css set to hide the element) for smoother page load.
	 * OPTIONAL - defaults to false
	 * @property {boolean} respondToHeight - respond to height as opposed to width.
	 * OPTIONAL - defaults to false
	 * @property {array, string} targets - list of ids to target elements to move,
	 * accepts an array of ids or a string with each id separated by a space.
	 * REQUIRED.
	 * @property {array, object} breakpoints - an array of objects (or single object) that defines the breakpoint(s), destination(s) and some other details.
	 * REQUIRED: Takes the following props:
	 *          @property {int} respondToMin - the width (or height) at which the element will be moved/duplicated.
	 *          REQUIRED.
	 *          @property {array, string} destinations - ids of elements where the target will be moved or duplicated (if more than one destination) to.
	 *          accepts an array of ids or a string with each id separated by a space.
	 *          REQUIRED.
	 *          @property {boolean} prepend - whether to prepend of append the target to the destination.
	 *          NB: define another breakpoint object with the same respondToMin to have a variation of prepend/appends for different destinations.
	 *          OPTIONAL - defaults to false (so will append).
	 *
	 * @param {int} setupNo - only passed when class is called back via the assignMultipleResponsiveRearranges function.
	 * NOT REQUIRED.
	 *
	 * @returns instance of itself (or an array of instances if multiple objects in the setup)
	 * NB: pass multiple setup objects if you'd like to move different targets to different locations at different break points.
	 */
	constructor(setup, setupNo) {
		//check setup and set up!
		if (!setup) {
			this.throwError("\n\nResponsiveRearrange: \n\nno setup for ResponsiveRearrange passed!");
		} else if (Array.isArray(setup)) {
			return assignMultipleResponsiveRearranges(setup);
		} else {
			setupNo ? (this.setupNo = setupNo) : false;
			setup.respondToHeight ? (this.respondToHeight = true) : false;
			setup.revealOnCompletion ? (this.revealOnCompletion = true) : false;

			// check targets exist and are an array
			this.targets = !setup.targets
				? this.throwError(
						`\n\nResponsiveRearrange: \nno targets defined in setup ${
							setupNo ? "array item number " + setupNo : ""
						} \n`
				  )
				: Array.isArray(setup.targets)
				? setup.targets
				: setup.targets.split(" ");
			//set the parent Element and ensure all targets are in the same container.
			let parentElement;
			for (let i = 0; i < this.targets.length; i++) {
				let t;
				t = this.targets[i];
				t = document.getElementById(t)
					? document.getElementById(t)
					: this.throwError(
							`\n\nResponsiveRearrange: \n'${t}' element does not exist in DOM ${
								setupNo ? `(object number ${setupNo} of the setup)` : ""
							} \n`
					  );
				// hide the element if not already hidden and is meant to be revealed
				this.revealOnCompletion && !t.hasAttribute("style")
					? t.setAttribute("style", "display:none;")
					: false;
				// assign the element as the target
				this.targets[i] = t;

				// check elements are in the same container
				parentElement =
					parentElement && parentElement !== t.parentElement
						? console.warn(
								`\n\nResponsiveRearrange: \ntargets should be in the same container; all target elements will be placed in the parent container of ${
									this.targets[this.targets.length - 1]
								}. Define them in separate objects in the setup (as an array of objects) to avoid this. \n\n`
						  )
						: t.parentElement;
			}

			// check breakpoints exist and are an array
			setup.breakpoints = !setup.breakpoints
				? this.throwError(
						`\n\nResponsiveRearrange: \nno breakpoints defined in setup ${
							setupNo ? `(object number ${setupNo} of the setup)` : ""
						} \n`
				  )
				: Array.isArray(setup.breakpoints)
				? setup.breakpoints
				: [setup.breakpoints];
			// loop through breakpoints, assign and check.
			this.breakpoints = [];
			let previousBreakpoint;
			previousBreakpoint = 0;
			for (let i = 0; i < setup.breakpoints.length; i++) {
				this.breakpoints = [...this.breakpoints, Object.assign({}, setup.breakpoints[i])];
				this.checkAndSetBreakPoint(this.breakpoints[i]);
				previousBreakpoint =
					setup.breakpoints[i] < previousBreakpoint
						? this.throwError(
								`\n\nResponsiveRearrange: \nensure all breakpoints are passed in ascending order ${
									setupNo ? `(see object number ${setupNo} of the setup)` : ""
								} \n`
						  )
						: setup.breakpoints[i];
			}
			// if no zero breakpoint is defined, add it to the array of breakpoints.
			this.breakpoints =
				this.breakpoints[0].respondToMin !== 0
					? [{respondToMin: 0, destinations: [parentElement]}, ...this.breakpoints]
					: this.breakpoints;
		}

		this.rearrange();
		const self = this;
		window.addEventListener("resize", function () {
			self.rearrange();
		});

		return this;
	}

	/**
	 * @method check params of the breakpoint object for errors and then set it up.
	 * @param {object} bp - the breakpoint object.
	 * REQUIRED.
	 */
	checkAndSetBreakPoint(bp) {
		let index = this.breakpoints.indexOf(bp) + 1;

		// check respond to min prop and ensure is a number
		bp.respondToMin =
			bp.respondToMin === undefined
				? this.throwError(
						`\n\nResponsiveRearrange: \nno 'respondToMin' prop defined in breakpoints array item number ${index} ${
							this.setupNo ? `(of object number ${this.setupNo} of the setup)` : ""
						} \n\n`
				  )
				: typeof bp.respondToMin !== "number"
				? parseInt(bp.respondToMin, 10)
				: bp.respondToMin;

		// check destinations prop and set as array if not already.
		bp.destinations = !bp.destinations
			? this.throwError(
					`\n\nResponsiveRearrange: \nno destination defined in breakpoints array item number ${index} ${
						this.setupNo ? `(of object number ${this.setupNo} of the setup)` : ""
					} \n\n`
			  )
			: typeof bp.destinations === "string"
			? bp.destinations.split(" ")
			: bp.destinations;

		// loop destination ids and set as elements fetched from the DOM
		for (let i = 0; i < bp.destinations.length; i++) {
			let destination;
			destination = bp.destinations[i];
			destination =
				typeof destination !== "string"
					? destination
					: document.getElementById(destination)
					? document.getElementById(destination)
					: this.throwError(
							`\n\nResponsiveRearrange: \nthe destination element '${
								bp.target
							}' does not exist in the DOM: \nsee breakpoints array item number ${index} ${
								this.setupNo ? `(of object number ${this.setupNo} of the setup)` : ""
							} \n\n`
					  );
			bp.destinations[i] = destination;
		}
	}

	/**
	 * @method find and set the closest breakpoint based on current window size.
	 */
	findClosestBreakpoint() {
		this.current = {};
		this.current.windowSize = this.respondToHeight ? window.innerHeight : window.innerWidth;
		this.current.closestRespondToMinBreakpoint = 0;
		for (const bp of this.breakpoints) {
			this.current.closestRespondToMinBreakpoint =
				this.current.windowSize > bp.respondToMin
					? bp.respondToMin
					: this.current.closestRespondToMinBreakpoint;
		}
	}

	/**
	 * @method move or duplicate targets based on current breakpoint.
	 */
	rearrange() {
		// remove all the cloned elements for this setup
		const clonedElements = document.querySelectorAll(
			`[data-responsiverearrangeclone${this.setupNo ? `='${this.setupNo}'` : ""}]`
		);
		for (const ce of clonedElements) {
			ce.parentNode.removeChild(ce);
		}

		this.findClosestBreakpoint();

		for (let target of this.targets) {
			let i;
			i = 0;
			for (const bp of this.breakpoints) {
				if (bp.respondToMin === this.current.closestRespondToMinBreakpoint) {
					for (const destination of bp.destinations) {
						if (i === 0) {
							this.attach(target, destination, bp.prepend);
							i++;
						} else {
							this.cloneAndAttach(target, destination, bp.prepend, i);
							i++;
						}
					}
				}
			}
		}

		return this;
	}

	/**
	 * @method duplicate the element and append/prepend to destination
	 * @param {element} target - element to duplicate
	 * REQUIRED.
	 * @param {element} destination - element to duplicate the target into.
	 * REQUIRED.
	 * @param {boolean} prepend - whether to prepend or append into the destination.
	 * OPTIONAL - defaults to false (append).
	 * @param {int} cloneNumber - the iteration on the cloning of the element (to add to the id for identification)
	 * REQUIRED.
	 */
	cloneAndAttach(target, destination, prepend, cloneNumber) {
		// clone the target
		const clone = target.cloneNode(true);
		// assign a unique id and an attribute assigned to setup number (if there are multiple setups)
		clone.setAttribute("data-responsiverearrangeclone", this.setupNo ? this.setupNo : "");
		clone.id = `${clone.id}-clone${cloneNumber}`;
		// append or prepend the cloned target element
		this.attach(clone, destination, prepend);
		// prepend ? destination.insertBefore(clone, destination.firstChild) : destination.appendChild(clone);
	}

	/**
	 * @method attach element to DOM (append/prepend to destination)
	 * @param {element} target - element to attach
	 * REQUIRED.
	 * @param {element} destination - element to insert the target into.
	 * REQUIRED.
	 * @param {boolean} prepend - whether to prepend or append into the destination.
	 * OPTIONAL - defaults to false (append).
	 */
	attach(target, destination, prepend) {
		prepend
			? destination.insertBefore(target, destination.firstChild)
			: destination.appendChild(target);

		if (this.revealOnCompletion) {
			target.removeAttribute("style");
		}
	}

	/**
	 * @method throw error
	 * @param {string} message - error message
	 */
	throwError(message) {
		throw new Error(message);
	}
}

/**
 * @function to assign multiple setups passed to the ResponsiveRearrange class
 * @param {object} setups - array of setup objects
 */
function assignMultipleResponsiveRearranges(setups) {
	let array = [];
	for (let i = 0; i < setups.length; i++) {
		array = [...array, new ResponsiveRearrange(setups[i], i + 1)];
	}
	return array;
}
