import {pathToArrayWithoutEmptySpaces} from "./pathToArrayWithoutEmptySpaces";

/**
 * @function MatchLocationPath check URL matches URL path
 * @param {array.<string>, string} matches - REQUIRED.
 * strings to match (Allows catch all astericks in string if exact param is false).
 * @param {boolean} exact - OPTIONAL. Defaults to true.
 * TRUE : match URL path exactly from start to finish.
 * e.g for 'path/to/thing', 'path/to', 'to/thing' or anyother combination other than 'path/to/thing' will return false.
 * FALSE : allow just part of the path starting from the begining
 * e.g for 'path/to/thing', 'path/to' will return true, 'to/thing' will return false.
 * @returns {boolean} - true if matches, false if not.
 */
export function matchLocationPath(matches, exact = true) {
	let URLpathMatch = false;
	let path = pathToArrayWithoutEmptySpaces(location.pathname);
	path = exact ? path.join("/") : path;

	//loop matches
	for (let match of typeof matches === "string" ? [matches] : matches) {
		//get each sub folder for the match in an array (and remove empty strings from array)
		match = pathToArrayWithoutEmptySpaces(match);

		//if exact, just check the match string equals the path string
		if (exact) {
			URLpathMatch = path === match.join("/");
		} //else we need to break down each subfolder for comparison check
		else {
			//loop each sub folder in match
			for (const [i, value] of match.entries()) {
				//check subfolder matches subfolder in path or is a catch all value
				URLpathMatch = Boolean(value === "*" && path[i]) || value === path[i];
				//break loop if no match as it is invalid
				if (!URLpathMatch) {
					break;
				}
			}
		}

		//break loop once found a complete match and return it
		if (URLpathMatch) {
			break;
		}
	}
	return URLpathMatch;
}
