import {hideAll} from "tippy.js";

export class Lightbox {
	constructor() {
		this.elements = {
			$overlay: $("#sf-lightbox"),
			$image: $("#sf-lightbox__img")
		};

		// Check if the necessary elements exist
		if (this.elements.$overlay.length === 0) {
			console.warn("Lightbox overlay element '#sf-lightbox' not found.");
		} else if (this.elements.$image.length === 0) {
			console.warn("Lightbox image element '#sf-lightbox__img' not found.");
		} else {
			this.setupEvents();
		}
	}

	setupEvents() {
		$("body").on("click", "[data-lightbox-content] img[src], [data-lightbox]", (e) => {
			this.openLightbox(e);
		}); // open on click of an image with a src attribute

		this.elements.$overlay.on("click", () => this.openLightbox(false)); // close on click of the overlay
	}

	openLightbox(event = null) {
		let open = false; // initiate an open state
		if (event && event.target) {
			const $img = $(event.target); // get the clicked image element
			const imgSrc = $img.attr("src"); // get the src attribute of the clicked image
			const notLightboxImage = $img[0] !== this.elements.$image[0]; // check the clicked image is not the lightbox image

			// if we have a src and the clicked image isn't the lightbox image...
			if (imgSrc && notLightboxImage) {
				this.elements.$image.attr("src", imgSrc); // ...set the lightbox image src to the clicked image src...
				open = true; // ... and set the lightbox state to open.
				hideAll({duration: 0}); // Hide all tooltips (for on mobile it stays open in front of the lightbox)
			}
		}
		open ? this.elements.$overlay.show() : this.elements.$overlay.hide(); // hide/show the lightbox depending on open state (false as default)
	}
}
